import { APP_MODE } from '../constants.utils';

const defaultUrl =
  APP_MODE === 'local'
    ? process.env.REACT_APP_API_LOCAL_URL
    : APP_MODE === 'staging'
    ? process.env.REACT_APP_API_STAGING_URL
    : APP_MODE === 'live'
    ? process.env.REACT_APP_API_LIVE_URL
    : window.location.origin + '/api/v1';

const defaultUrl2 =
  APP_MODE === 'local'
    ? process.env.REACT_APP_PUBLIC_URL_V2
    : APP_MODE === 'staging'
    ? process.env.REACT_APP_PUBLIC_URL_V2
    : APP_MODE === 'live'
    ? process.env.REACT_APP_PUBLIC_URL_V2
    : window.location.origin + '/api/v1';
const defaultErrorMessage = 'Oops. Something went wrong.';

export { defaultUrl, defaultUrl2, defaultErrorMessage };
