import {POST, FETCH, PUT, createUrl, createUrl2} from '../../utils/api';

import {
  propertySaveRequest,
  propertySaveSuccess,
  propertySaveError,
  propertyGetSaveRequest,
  propertyGetSaveSuccess,
  propertyGetSaveError,
  propertySearchPerfectProperty,
  propertySearchPerfectPropertyError,
  propertySearchPerfectPropertySuccess,
  propertySaveSearch,
  propertySaveSearchSuccess,
  propertySaveSearchError,
  propertySearch,
  propertySearchSuccess,
  propertySearchError,
  propertySearchRental,
  propertySearchRentalError,
  propertySearchRentalSuccess,
  propertySearchSold,
  propertySearchSoldError,
  propertySearchSoldSuccess,
  propertyDetailsRequest,
  propertyDetailsRequestError,
  propertyDetailsRequestSuccess,
  propertyEditRequest,
  propertyEditSuccess,
  propertyEditError,
  sendAgentContactRequest,
  sendAgentContactError,
  sendAgentContactSuccess,
  requestLikedPropertiesRequest,
  requestLikedPropertiesError,
  requestLikedPropertiesSuccess,
  requestDislikedPropertiesRequest,
  requestDislikedPropertiesError,
  requestDislikedPropertiesSuccess,
  requestPropertySuggestions,
  requestPropertySuggestionsError,
  requestPropertySuggestionsSuccess,
  sendLikesDislikesPropertiesRequest,
  sendLikesDislikesPropertiesError,
  sendLikesDislikesPropertiesSuccess,
  sendSearchTalliesRequest,
  sendSearchTalliesError,
  sendSearchTalliesSuccess,
  sendPerfectPropertiesFilters,
  sendPerfectPropertiesFiltersError,
  sendPerfectPropertiesFiltersSuccess,
  getPropertySaveSearch,
  getPropertySaveSearchError,
  getPropertySaveSearchSuccess,
  deletePropertySaveSearch,
  deletePropertySaveSearchError,
  deletePropertySaveSearchSuccess,
  putPropertySaveSearch,
  putPropertySaveSearchError,
  putPropertySaveSearchSuccess,
  requestFavoritePropertiesRequest,
  requestFavoritePropertiesError,
  requestFavoritePropertiesSuccess,
  requestDreamProperties,
  requestDreamPropertiesSuccess,
  requestDreamPropertiesError,
  requestVendorOTP,
  requestVendorOTPSuccess,
  requestVendorOTPFail,
} from '../actions';

import {errorhandler} from '../api';

import {apiEndpoints} from '../../utils/api/endpoints';

const urlSetter = (params, endpoint) => {
  let URL = endpoint;
  if (params.query && params.query !== '') {
    URL = endpoint + params.query;
  }
  return URL;
};

export function requestPropertySave(params) {
  return (dispatch) => {
    dispatch(propertySaveRequest(params));
    POST(apiEndpoints.PROPERTY_SAVE, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(propertySaveSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(propertySaveError(errorhandler(error)));
      });
  };
}

export function requestGetPropertySave(params) {
  return (dispatch) => {
    dispatch(propertyGetSaveRequest(params));
    FETCH(apiEndpoints.PROPERTY_SAVE)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(propertyGetSaveSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(propertyGetSaveError(errorhandler(error)));
      });
  };
}

export function requestPropertySearch(params) {
  return (dispatch) => {
    dispatch(propertySearch(params));
    POST(apiEndpoints.PROPERTY_SEARCH, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(propertySearchSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(propertySearchError(errorhandler(error)));
      });
  };
}

export function requestPropertyDetails(params) {
  return (dispatch) => {
    dispatch(propertyDetailsRequest(params));
    FETCH(apiEndpoints.PROPERTY_DETAILS + params.property, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(propertyDetailsRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(propertyDetailsRequestError(errorhandler(error)));
      });
  };
}

export function requestPropertyEdit(params) {
  return (dispatch) => {
    dispatch(propertyEditRequest(params));
    PUT(apiEndpoints.PROPERTY_DETAILS + params.property, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(propertyEditSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(propertyEditError(errorhandler(error)));
      });
  };
}

export function requestContactAgent(params) {
  return (dispatch) => {
    let request = {
      contactMethod: 'email',
      subject: params.subject,
      name: params.name,
      email: params.email,
      phone: params.phone,
      postcode: params.postcode,
      message: params.message,
      inquiry: params.inquiry,
    };
    dispatch(sendAgentContactRequest(params));
    POST('/property/' + params.id + '/contact', request)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(sendAgentContactSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(sendAgentContactError(errorhandler(error)));
      });
  };
}

export function requestLikeProperties(params) {
  return (dispatch) => {
    dispatch(requestLikedPropertiesRequest(params));
    let query = '';
    if (params) {
      query = '?take=' + params.take + '&skip=' + params.skip;
    } else {
      query = '';
    }
    FETCH(apiEndpoints.LIKE_DISLIKE_PROPERTY + query)
      // FETCH(apiEndpoints.LIKE_DISLIKE_PROPERTY)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(requestLikedPropertiesSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(requestLikedPropertiesError(errorhandler(error)));
      });
  };
}

export function requestFavoriteProperties(params) {
  return (dispatch) => {
    dispatch(requestFavoritePropertiesRequest(params));
    POST(apiEndpoints.FAVORITE_PROPERTIES, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(requestFavoritePropertiesSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(requestFavoritePropertiesError(errorhandler(error)));
      });
  };
}

export function requestDislikedProperties(params) {
  return (dispatch) => {
    dispatch(requestDislikedPropertiesRequest(params));
    FETCH(apiEndpoints.LIKE_DISLIKE_PROPERTY, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(requestDislikedPropertiesSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(requestDislikedPropertiesError(errorhandler(error)));
      });
  };
}

export function requestSendLikesDislikesProperties(params) {
  return (dispatch) => {
    dispatch(sendLikesDislikesPropertiesRequest(params));
    POST(apiEndpoints.LIKE_DISLIKE_PROPERTY, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(sendLikesDislikesPropertiesSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(sendLikesDislikesPropertiesError(errorhandler(error)));
      });
  };
}

export function requestSendSearchTallies(params) {
  return (dispatch) => {
    dispatch(sendSearchTalliesRequest(params));
    POST(apiEndpoints.SEARCH_TALLIES, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(sendSearchTalliesSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(sendSearchTalliesError(errorhandler(error)));
      });
  };
}

export function requestSearchPerfectProperty(params) {
  const URL = urlSetter(params, apiEndpoints.PERFECT_PROPERTY_RECOMMENDATION);
  return (dispatch) => {
    dispatch(propertySearchPerfectProperty(params));
    POST(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(propertySearchPerfectPropertySuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(propertySearchPerfectPropertyError(errorhandler(error)));
      });
  };
}

export function requestSendPerfectPropertyFilters(params) {
  return (dispatch) => {
    dispatch(sendPerfectPropertiesFilters(params));
    POST(apiEndpoints.PERFECT_PROPERTY_FILTERS, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(sendPerfectPropertiesFiltersSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(sendPerfectPropertiesFiltersError(errorhandler(error)));
      });
  };
}

export function requestPropertySuggestion(params) {
  return (dispatch) => {
    dispatch(requestPropertySuggestions(params));
    POST(apiEndpoints.PROPERTY_SUGGESTIONS + '?id=' + params.id + '&take=20', {
      propertyStatusList: params.status ?? [],
    })
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(requestPropertySuggestionsSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(requestPropertySuggestionsError(errorhandler(error)));
      });
  };
}

export function requestPropertySearchRentals(params) {
  return (dispatch) => {
    dispatch(propertySearchRental(params));
    POST(apiEndpoints.PROPERTY_RENT, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(propertySearchRentalSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(propertySearchRentalError(errorhandler(error)));
      });
  };
}

export function requestPropertySearchSold(params) {
  return (dispatch) => {
    dispatch(propertySearchSold(params));
    POST(apiEndpoints.PROPERTY_SOLD, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(propertySearchSoldSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(propertySearchSoldError(errorhandler(error)));
      });
  };
}

export function requestPropertySaveSearch(params) {
  return (dispatch) => {
    dispatch(propertySaveSearch(params));
    POST(apiEndpoints.PROPERTY_SAVE_SEARCH, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(propertySaveSearchSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(propertySaveSearchError(errorhandler(error)));
      });
  };
}

export function requestGetAllSavedSearch(params) {
  return (dispatch) => {
    dispatch(getPropertySaveSearch(params));
    FETCH(apiEndpoints.PROPERTY_SAVE_SEARCH, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(getPropertySaveSearchSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(getPropertySaveSearchError(errorhandler(error)));
      });
  };
}

export function requestDeleteSavedSearchItem(params) {
  return (dispatch) => {
    dispatch(deletePropertySaveSearch(params));
    POST(apiEndpoints.PROPERTY_SAVE_SEARCH_DELETE, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(deletePropertySaveSearchSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(deletePropertySaveSearchError(errorhandler(error)));
      });
  };
}

export function requestPutSavedSearchItem(params) {
  return (dispatch) => {
    dispatch(putPropertySaveSearch(params));
    PUT(apiEndpoints.PROPERTY_SAVE_SEARCH_NOTIFY, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(putPropertySaveSearchSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(putPropertySaveSearchError(errorhandler(error)));
      });
  };
}

export function getDreamProperties(params) {
  return (dispatch) => {
    dispatch(requestDreamProperties(params));
    POST(apiEndpoints.DREAM_PROPERTIES, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(requestDreamPropertiesSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(requestDreamPropertiesError(errorhandler(error)));
      });
  };
}

export function postVendorOTP(propertyId, params) {
  return (dispatch) => {
    dispatch(requestVendorOTP(params));
    POST(apiEndpoints.VENDOR_VERIFY_OTP + propertyId, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(requestVendorOTPSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(requestVendorOTPFail(errorhandler(error)));
      });
  };
}

export const addressSearch = async (value) => {
  const response = await fetch(createUrl(apiEndpoints.PROPERTY_ADDRESS), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      searchQuery: value,
    }),
  });
  const {data} = await response.json();
  return data;
};

export const propertyAddressSearch = async (value) => {
  const params = new URLSearchParams();
  params.append('query', value);

  const response = await fetch(`${createUrl2(apiEndpoints.PROPERTY_ADDRESS_SEARCH)}?${params.toString()}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_API2_KEY,
    },
  });
  const data = await response.json();
  return data;
};
