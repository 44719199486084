import React, {
  Fragment,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  Card,
  CircularProgress,
  Divider,
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  Box,
  InputLabel,
  InputAdornment,
  IconButton,
  Link,
  Menu,
  MenuItem,
  withStyles,
  makeStyles,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Select,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useFormik } from 'formik';
import * as yup from 'yup';
// import { useTheme } from '@material-ui/core/styles'
import { SuperStatusIcon } from 'components/Web/Superadmin';
import { SuperStatusAgency } from 'components/Web/Superadmin';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';

import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import { HelpOutline } from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';
import CustomDatePicker from '../../Filter/CustomDatePicker';
import CustomSelect from '../../Filter/CustomSelect';
import moment from 'moment';
import InputMask from 'react-input-mask';
import SuburbSearchField from 'components/Common/Fields/SuburbSearchField';
import CompanyInfoSearchField from 'components/Common/Fields/CompanyInfoSearchField';
import SelectField from 'components/Web/Form/SelectField';
import { STATES, PERIOD } from 'constants/constants';
import checkField from 'components/Common/Validator/validationService';
import { AGENCY_REGISTER_VALIDATION_CONFIG } from 'constants/constants';
import SUBURBS from '../../../../assets/data/suburbs-all-new.json';
import { CRMS } from 'constants/constants';
import { requestPOSTCreateAgencyAPI } from 'store/api';
import { useDispatch, useSelector } from 'react-redux';
import SelectPackage from '../../../Common/Billing/SelectPackage';
import { DateTime } from 'luxon';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import styled, { css } from 'styled-components';
import defaultBanner from '../../AgencyAccountSettings/imgs/default-banner.jpg';
import { useRouterChange } from 'utils/router.utils';
import { FilterNoneSharp } from '@material-ui/icons';
import Snackybar from '../Common/Snackybar';
import { QRCodeCanvas } from 'qrcode.react';
import downloadQRCode from 'utils/downloadComponentAsImage.utils';
import ReferralDataCards from '../Referral/ReferralDataCards';
import { dummyReferralData, QRCodeFileFormats } from 'constants/referral';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import downloadComponentAsImage from 'utils/downloadComponentAsImage.utils';
import PropertyMateLogo from '../../../../assets/images/propertymate_logo.svg';
import DownloadQRCodeUI from 'components/Common/Referral/DownloadQRCodeUI';
import { requestPOSTAPIAgencyReferral } from 'store/api';
import useSize from 'utils/customHooks/useSize';
import roundOffTwoDecimal from 'utils/roundOffTwoDecimal.utils';
import ReferralDatePicker from 'components/Common/Referral/ReferralDatePicker';
import { cleanDateToday } from 'utils/cleanDateToday';
import { computeDateDiff } from 'utils/computeDateDiff';
import { useParams } from 'react-router-dom';
import { initial } from 'lodash';
import { getUser } from 'store/selectors/superadmin.selectors';
import DocusignPreviewDialog from 'components/Web/DialogContainer/DocusignPreviewDialog';
import { identity } from 'lodash';

const FormInput = (props) => (
  <div style={{ marginTop: 10 }}>
    <Typography variant={'caption'} style={{ color: 'gray' }}>
      {props.label}
    </Typography>
    <TextField
      style={{ backgroundColor: '#FFF' }}
      {...props}
      label={null}
      fullWidth
      id='outlined-basic'
      variant='outlined'
    />
  </div>
);

const ButtonOptions = ({ icon, onClick, label, ...props }) => (
  <Button
    variant='text'
    color='inherit'
    size='large'
    startIcon={icon}
    onClick={onClick}
    {...props}
  >
    <Typography
      variant={'body2'}
      style={{
        color: 'rgba(0, 0, 0, 0.87)',
        textTransform: 'capitalize',
        fontWeight: 300,
        fontSize: 22,
        textAlign: 'left',
        marginLeft: 15,
        marginTop: 10,
      }}
    >
      {label}
    </Typography>
  </Button>
);

const optionContainer = {
  marginTop: 15,
  width: '100%',
  height: 'auto',
  borderRadius: 20,
  backgroundColor: '#faf7ef',
  padding: 20,
};

const styles = (theme) => ({
  root: {
    marginTop: 20,
    marginRight: 20,
    paddingBottom: 60,
    width: '89vw',
  },
  title: {
    fontWeight: 300,
    fontSize: '21px',
    marginBottom: '10px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
    textAlign: 'left',
  },
  formContainer: {
    width: '100%',
    height: 'auto',
    borderRadius: 20,
    backgroundColor: '#f5f5f5',
    padding: 20,
  },
  optionContainer,
  ippReferralContainer: {
    ...optionContainer,
    backgroundColor: '#E7F7F9',
  },
  buttonContainer: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  saveButton: {
    height: 50,
    marginRight: 5,
    borderRadius: 40,
  },
  docusignButton: {
    height: 50,
    marginLeft: 5,
    borderRadius: 40,
    backgroundColor: '#fdbd26',
    borderColor: '#fdbd26',
    '&:hover': {
      backgroundColor: 'rgba(253, 189, 38, 0.8)',
    },
  },
  brandingButton: {
    height: 50,
    marginLeft: 5,
    borderRadius: 40,
    backgroundColor: '#fdbd26',
    borderColor: '#fdbd26',
    '&:hover': {
      backgroundColor: 'rgba(253, 189, 38, 0.8)',
    },
    marginTop: 50,
  },
  buttonOptionsContainer: {
    marginTop: 20,
  },
  buttonIcon: {
    width: 35,
    height: 35,
    borderRadius: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 25,
  },
  CRBox: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '38px',
    padding: '3rem',
    width: '99%',
  },
  CRDescriptions: { display: 'flex' },
  CRDoneButton: {
    flexShrink: '1!important',
    background: '#6FCF97',
    borderRadius: '20px',
    width: '155px',
    height: '44.96px',
    color: '#ffffff',
    marginTop: '5rem',
  },
  CRDescriptionLine: {
    width: '100%',
    '& p': {
      paddingTop: '0.4rem',
    },
  },
  crBold: {
    fontWeight: 600,
  },
  docusignStatusChip: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    textAlign: 'center',
    lineHeight: '34px',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  paymentStatusChip: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    textAlign: 'center',
    lineHeight: '34px',
    marginTop: '2rem',
  },
  primaryBtn: {
    background: '#FFFFFF',
    border: '0.5px solid #35C0CA',
    borderRadius: '20px',
    padding: '9px 20px',
    cursor: 'pointer',
    outline: 'none',
    transition: '0.3s ease',
    '&:hover': {
      opacity: 0.7,
    },
  },
  closeicon: {
    position: 'absolute',
    right: '15px',
    top: '15px',
  },
  imgLogo: {
    //position: 'absolute',
    //top: '50%',
    left: 0,
    right: 0,
    //transform: 'translateY(-50%)',
    maxHeight: '100px',

    //margin: '0 auto',
    objectFit: 'contain',
  },
  imgBanner: {
    height: '200px',
    //width: '100%',
    objectFit: 'cover',
  },
  changeImageButton: {
    background: '#FFFFFF',
    border: '0.5px solid #35C0CA',
    borderRadius: '20px',
    padding: '10px 35px',
    cursor: 'pointer',
    outline: 'none',
    transition: '0.3s ease',
    color: '#35C0CA',
    '&:hover': {
      background: '#35C0CA',
      color: 'white',
    },
    margin: '25%',
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

const FormModal = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 38px;
  width: 100%;
  max-height: 760px;
  max-width: 500px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 6;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease;
  top: 50%;
  transform: translateY(-50%);
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: -0.525px;
    color: #4d4d4d;
    margin-bottom: 15px;
  }
  .modal-wrap {
    padding: 30px;
    .button-wrap {
      padding-top: 25px;
      display: flex;
      justify-content: flex-end;
    }
    .public-DraftEditor-content {
      height: 250px;
      overflow-y: auto;
      border: 1px solid rgba(0, 0, 0, 0.23);
      box-sizing: border-box;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 0 15px;
    }
  }
  .form-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
    .logo-label {
      text-align: center;
    }

    label {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.09px;
      color: rgba(0, 0, 0, 0.54);
      margin-bottom: 5px;
    }

    input {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.23);
      box-sizing: border-box;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: 56px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
      padding: 15px;
      transition: outline 0.3s ease;
      &:focus {
        outline-color: #35c0ca !important;
      }
    }
    textarea {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.23);
      box-sizing: border-box;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      min-height: 100px;
      font-size: 14px;
      font-family: inherit;
      padding: 15px;
      resize: none;
      width: 100%;
      transition: outline 0.3s ease;
      &:focus {
        outline-color: #35c0ca !important;
      }
    }
  }
`;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  firstName: yup
    .string('Enter you first name')
    .required('First name is required'),
  lastName: yup.string('Enter you last name').required('Last name is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  adminEmail: yup
    .string('Enter your email')
    .email('Enter a valid admin email')
    .required('Admin Email is required'),
  enquiryEmailAddress: yup
    .string('Enter your email')
    .email('Enter a valid Enquiry email')
    .required('Enquiry email is required'),
  contactPrincipalEmail: yup
    .string('Enter your email')
    .email('Enter a valid principal email')
    .required('Principal Email is required'),
  contactAccountPayableEmail: yup
    .string('Enter your email')
    .email('Enter a valid accounts payable email')
    .required('Accounts Payable Email is required'),
  phone: yup
    .string('Enter your phone number')
    .required('Phone number is required'),
  signUpPermission: yup
    .string('Enter permission to signup')
    .required('Permission to signup is required'),
  // hdyfu: yup.string('Enter where you hear about us').required('Source is required'),
  //phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  /*   password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length'), */
  /*     .required('Password is required'),
   */
  companyName: yup
    .string('Enter your Company Name')
    .required('Company Name is required'),
  registeredTradeName: yup
    .string('Enter your Trade Name')
    .required('Trade Name is required'),
  companyWebsite: yup
    .string('Enter your company website')
    .required('Company Website is required'),
  abn: yup.string('Enter your abn').required('ABN is required'),
  realEstateLicenseNumber: yup
    .string('Enter your Real Estate License Number')
    .required('Real Estate License Number is required'),
  businessNumber: yup
    .string('Enter your business number')
    .required('Business number is required'),
  address: yup.string('Enter your address').required('Address is required'),
  contactPrincipalName: yup
    .string('Enter your Principal Name')
    .required('Principal Name is required'),
  contactAccountPayableName: yup
    .string('Enter your Accounts Payable Name')
    .required('Accounts Payable Name is required'),
  contactBillingAddress: yup
    .string('Enter your Contact Billing Address')
    .required('Billing Address is required'),
  contactSendListingOffice: yup
    .string('Enter your Listing Office')
    .required('Listing Office is required'),
});

const AdminInfoFieldA = [
  { name: 'firstName', label: 'First Name' },
  { name: 'lastName', label: 'Last Name' },
  { name: 'email', label: 'Signatory Email Address' },
  { name: 'adminName', label: 'Admin Name (Admin Email Owner)' },
  { name: 'adminEmail', label: 'Administration Email Address' },
  { name: 'phone', label: 'Phone Number' },
  //{ name: 'password', label: 'Password' }, // Not on api
  { name: 'signUpPermission', label: 'Permission to sign-up' },
  { name: 'hdyfu', label: 'How did you hear about us?' }, // Not on api
  { name: 'salesPerson', label: 'Sales Person' },
];

const AdminInfoFieldB = [
  { name: 'companyName', label: 'Company Name' },
  { name: 'registeredTradeName', label: 'Registered Trade Name' },
  { name: 'companyInfo', label: 'Brand' },
  { name: 'companyWebsite', label: 'Company Website' },
  { name: 'abn', label: 'ABN' },
  { name: 'realEstateLicenseNumber', label: 'Real Estate Licence Number' },
  { name: 'enquiryEmailAddress', label: 'Enquiry Email Address' },
  { name: 'businessNumber', label: 'Office Phone Number' },
  { name: 'address', label: 'Office Address' },
  { name: 'suburb', label: 'Suburb' },
  /*   { name: 'postcode', label: 'Postcode' },
  { name: 'state', label: 'State' }, */
];

const AdminInfoFieldC = [
  { name: 'contactPrincipalName', label: 'Principal Name' },
  { name: 'contactPrincipalEmail', label: 'Principal Email Address' },
  { name: 'contactAccountPayableName', label: 'Accounts Payable Name' },
  { name: 'contactAccountPayableEmail', label: 'Accounts Payable Email' },
  { name: 'contactBillingAddress', label: 'Billing Address' },
  { name: 'contactSuburb', label: 'Contact Suburb' },
  /*   { name: 'crm', label: 'Bulk Uploader Provider' },
  {
    name: 'contactSendListingOffice',
    label: 'Which offices do you want to send listing from?',
  }, */
];

const AgencyDetailsUpgradePaidForm = (props) => {
  const {
    classes,

    match,
    handleConfirmDialogOpen,
    initialData,
    packages,
    agencies,
    offices,
    isLoading,
    isChangeRequests,
    changeRequestsData,
    uploadedImage,
    handleOfficeDetails,
    requestUploadImage,
    requestClearUploadImage,

    office,
    setOffice,
    officeId,
    setOfficeId,
    officesHandler,
    ippReferralData,
    ippSelectDate,
    setIppSelectDate,
    referralLoading,
    handleStartDateChange,
    handleEndDateChange,
    ippStartDate,
    setIppStartDate,
    ippEndDate,
    setIppEndDate,
    handleBackgroundSave,
  } = props;
  // const theme = useTheme()
  // let isMdView = useMediaQuery(theme.breakpoints.up('md'))
  const [routerChange] = useRouterChange();

  //subscription states
  const subscriptionStatuses = [
    'SUBSCRIPTION_PENDING',
    'SUBSCRIPTION_TRIAL_ACTIVE',
    'SUBSCRIPTION_ACTIVE',
    'SUBSCRIPTION_CANCELED',
  ];
  //docusign states
  const docusignStatuses = [
    'CONTRACT_PENDING',
    'CONTRACT_SENT',
    'CONTRACT_SIGNED',
    'CONTRACT_RESEND',
    'CONTRACT_REJECTED',
    'CONTRACT_STALE',
  ];

  const subscriptionPaymentStatuses = [
    'SUBSCRIPTION_PAYMENT_PENDING',
    'SUBSCRIPTION_PAYMENT_CHARGED',
    'SUBSCRIPTION_PAYMENT_FAILED',
  ];

  const invoicePaymentStatuses = [
    'INVOICE_ISSUED',
    'INVOICE_DUE',
    'INVOICE_REMINDER_SENT',
    'INVOICE_PAID',
  ];

  const dispatch = useDispatch();
  const today = DateTime.now().toUTC().toISO();
  const { superadmintoken } = useSelector(getUser);
  const currentUser = superadmintoken?.superadmin;
  const [salesPerson, setSalesPerson] = useState(
    `${currentUser?.firstName} ${currentUser?.lastName}`
  );
  const { isSmall, isMedium, isLarge } = useSize();
  const [editBillingDate, setEditBillingDate] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [brandColor, setBrandColor] = useState(initialData?.brandColor || null);
  const [logoUrl, setLogoUrl] = useState(initialData?.logoUrl);
  const [coverImageUrl, setCoverImageUrl] = useState(
    initialData?.coverImageUrl || defaultBanner
  );
  const [uploadTarget, setUploadTarget] = useState(null);
  const [refError, setRefError] = useState(null);
  const [startDate, setStartDate] = React.useState(
    moment
      .utc(initialData?.subscription?.startDate)
      .format('YYYY-MM-DDT00:00:00.000') || today
  );
  const [billingDate, setBillingDate] = React.useState(
    moment
      .utc(initialData?.subscription?.billingDate)
      .format('YYYY-MM-DDT00:00:00.000') || today
  );

  const [trialPeriodDayCount, setTrialPeriodDayCount] = React.useState(
    initialData?.subscription?.trialPeriodDayCount || 0
  );

  const [effectiveDate, setEffectiveDate] = React.useState(
    moment
      .utc(initialData?.subscription?.effectiveDate)
      .format('YYYY-MM-DDT00:00:00.000') || today
  );

  const [contactBillingAddress, setContactBillingAddress] = useState(
    initialData?.contactBillingAddress ?? ''
  );

  // Snackybar state
  const [showMessage, setShowMessage] = useState({
    open: false,
    type: 'info',
    message: '',
  });

  //suburb states
  const [openSuburbList, setOpenSuburbList] = useState(false);
  const [suburbInputValue, setSuburbInputValue] = useState(
    initialData?.suburb || ''
  );
  const [suburb, setSuburb] = useState(initialData?.suburb || '');
  const [postCode, setPostCode] = useState(initialData?.postcode || '');
  const [state, setState] = useState(initialData?.state || '');
  const [contactSuburbAutoCompleteValue, setContactSuburbAutoCompleteValue] =
    useState();

  const [companyInfoInputValue, setCompanyInfoInputValue] = useState(
    initialData?.companyInfo || ''
  );
  const [isCompanyInfoError, setIsCompanyInfoError] = useState(false);

  const [isSameAddress, setIsSameAddress] = useState(false);
  const [isSameAsListingProvider, setIsSameAsListingProvider] = useState(false);

  useEffect(() => {
    const checkFieldEquality = (field1, field2) => {
      if (!field1 || !field2) return false;

      return field1.toLowerCase() === field2.toLowerCase();
    };

    const isSameLocation = checkFieldEquality(
      initialData?.address,
      initialData?.contactBillingAddress
    );
    const isSameSuburb = checkFieldEquality(
      initialData?.suburb,
      initialData?.contactSuburb
    );
    const isSamePostcode = checkFieldEquality(
      initialData?.postcode,
      initialData?.contactPostcode
    );
    const isSameState = checkFieldEquality(
      initialData?.state,
      initialData?.contactState
    );

    setIsSameAddress(
      isSameLocation && isSameSuburb && isSamePostcode && isSameState
    );
    setIsSameAsListingProvider(
      initialData?.crmRental === initialData?.crmSales
    );
  }, [initialData]);

  // Autocomplete Postcode and state based from suburb
  useEffect(() => {
    if (suburb && suburb.postcode && suburb.state) {
      setPostCode(suburb.postcode);
      setState(suburb.state);
    }
  }, [suburb]);

  // contact suburb states
  const [openContactSuburbList, setOpenContactSuburbList] = useState(false);
  const [suburbContactInputValue, setSuburbContactInputValue] = useState(
    initialData?.contactSuburb || ''
  );
  const [contactSuburb, setContactSuburb] = useState(
    initialData?.contactSuburb || ''
  );
  const [contactPostcode, setContactPostcode] = useState(
    initialData?.contactPostcode ?? ''
  );
  const [contactState, setContactState] = useState(
    initialData?.contactState || ''
  );

  const [crmSales, setCrmSales] = useState(initialData?.crmSales || '');
  const [manualCrmSales, setManualCrmSales] = useState(
    initialData?.crmSales !== '' && CRMS.includes(initialData?.crmSales)
      ? initialData?.crmSales
      : initialData?.crmSales !== ''
      ? 'Other'
      : ''
  );

  const [crmRental, setCrmRental] = useState(initialData?.crmRental || '');
  const [manualCrmRental, setManualCrmRental] = useState(
    initialData?.crmRental !== '' && CRMS.includes(initialData?.crmRental)
      ? initialData?.crmRental
      : initialData?.crmRental !== ''
      ? 'Other'
      : ''
  );

  //referral states
  const [ippReferralCheckbox, setIppReferralCheckbox] = useState(false);
  const [qrDownloadLoading, setQrDownloadLoading] = useState(false);
  const [qrFileFormat, setQrFileFormat] = useState('');
  const [qrFormatList, setQrFormatList] = useState(null);
  const openQrList = Boolean(qrFormatList);

  const ippReferralAnalytics = [
    {
      title: 'Number of Clicks',
      data: ippReferralData?.numberOfClicks,
    },
    { title: 'Number of Sign Ups', data: ippReferralData?.numberOfSignUps },
    { title: 'Bounce Rate', data: ippReferralData?.bounceRate },
  ];

  const qrCodeRef = useRef();

  const [officesOptions, setOfficesOptions] = useState('');
  const [packageOptions, setPackageOptions] = useState();
  const [stripePackage, setStripePackage] = useState(
    initialData?.subscription?.subscriptionPackageId
      ? initialData?.subscription?.subscriptionPackageId
      : null
  );
  const [stripePackagePeriod, setStripePackagePeriod] = useState(
    initialData?.subscription?.billingPeriodType &&
      initialData?.subscription?.billingPeriodType !== 'NONE'
      ? initialData?.subscription?.billingPeriodType
      : initialData?.subscription?.subscriptionPackagePriceInterval &&
        initialData?.subscription?.subscriptionPackagePriceInterval !== 'NONE'
      ? initialData?.subscription?.subscriptionPackagePriceInterval
      : null
  );

  const [subscriptionExpiration, setSubscriptionExpiration] = useState(0);
  const { id } = useParams();
  const [showDocusignPreview, setShowDocusignPreview] = useState(false);

  const computeSubscriptionExpiration = () => {
    const today = cleanDateToday();
    const expirationDate = new Date(effectiveDate);
    let dateDiff = 0;

    if (expirationDate) {
      dateDiff = computeDateDiff(today, expirationDate);
    } else {
      dateDiff = 0;
    }

    return dateDiff > 0 ? dateDiff : 0;
  };

  useEffect(() => {
    setSubscriptionExpiration(computeSubscriptionExpiration());
  }, [effectiveDate]);

  useEffect(() => {
    if (contactSuburb && contactSuburb.postcode && contactSuburb.state) {
      setContactPostcode(contactSuburb.postcode);
      setContactState(contactSuburb.state);
    }
  }, [contactSuburb]);

  const SuburbStateContainer = ({ children }) => {
    return <Box marginTop='10px'>{children}</Box>;
  };

  const handleGetOfficeLabel = (officeValue) => {
    if (officesOptions) {
      const officeDetails = officesOptions.filter(
        (office) => office.value === officeValue
      )[0];

      return officeDetails?.label;
    }

    return null;
  };

  const handleDownloadQRClick = (event) => {
    setQrFormatList(event.currentTarget);
  };

  const handleEditBillingDate = (value) => {
    setEditBillingDate(value.target.checked);
  };

  const handleStartDate = (data) => {
    let newDate = moment(data).format('YYYY-MM-DDT00:00:00.000');
    setStartDate(newDate);
  };

  const handleEffectiveDate = (data) => {
    let newDate = moment(data).format('YYYY-MM-DDT00:00:00.000');
    setEffectiveDate(newDate);
  };

  /*   const handleManualBilingDate = (data) => {
    let newDate = moment(data).toISOString()
    setManualBillingDate(newDate)
  } */

  const handleBillingDate = (data) => {
    let newDate = moment(data).format('YYYY-MM-DDT00:00:00.000');
    setBillingDate(newDate);
  };

  const handleStripePackage = (data) => {
    setStripePackage(data);
  };

  const handleStripePackagePeriod = (data) => {
    setStripePackagePeriod(data);
  };

  const handlePreventCutCopyPaste = (event) => {
    event.preventDefault();
  };

  const referralCodeLink = `${process.env.REACT_APP_REDIRECT_URL}/referral/${
    initialData.referralCode ?? initialData.referralCode
  }`;

  const handleCopyClipboard = () => {
    navigator.clipboard.writeText(referralCodeLink);
    setShowMessage({
      open: true,
      type: 'info',
      message: 'Referral Code copied to clipboard',
    });
  };

  const handleMessage = (state) => {
    setShowMessage({
      ...showMessage,
      open: state,
    });
  };

  const stripePackageHandler = (stripePackages) => {
    const selectPackagesField = [];

    let singlePackage = {};

    if (stripePackages) {
      stripePackages.forEach((stripepackage) => {
        if (stripepackage.subscriptionType !== 'EXTENDED FREE') {
          singlePackage = {
            label: `${stripepackage?.name}`,
            id: stripepackage?.pkgId,
            value: stripepackage?.pkgId,
          };
          selectPackagesField.push(singlePackage);
        }
      });
    }

    return selectPackagesField;
  };

  useMemo(() => {
    setPackageOptions(stripePackageHandler(packages));
  }, [packages]);

  const handleOffice = (data) => {
    setOffice(data);
    setOfficeId(data);

    const agencySummaryId =
      officesOptions[officesOptions?.findIndex((obj) => obj.value == data)]
        ?._id;
    if (data !== 'pleaseSelect') {
      handleOfficeDetails(agencySummaryId);
    }
  };

  useMemo(() => {
    setOfficesOptions(officesHandler(offices));
  }, [offices]);

  useEffect(() => {
    if (
      officesOptions?.findIndex(
        (obj) => obj.value == initialData?.reaAgencyId
      ) == '-1'
    ) {
      setOffice('pleaseSelect');
    } else {
      setOffice(initialData?.reaAgencyId);
    }
  }, [officesOptions, initialData?.reaAgencyId]);

  const formik = useFormik({
    initialValues: {
      //billing row
      subscription: {
        startDateString: startDate,
        billingDateString: billingDate,
        effectiveDateString: effectiveDate,
        trialPeriodDayCount: trialPeriodDayCount,
        type: stripePackage,
      },
      // row 1
      firstName: initialData?.firstName ?? '',
      lastName: initialData?.lastName ?? '',
      email: initialData?.email ?? '',
      adminEmail: initialData?.adminEmail ?? '',
      phone: initialData?.phone ?? '',
      //password: initialData?.password ?? '', // Not on api yet
      signUpPermission: initialData?.signUpPermission ?? '',
      hdyfu: initialData?.hdyfu ?? '', // Not on api
      // row 2
      companyName: initialData?.companyName ?? '',
      registeredTradeName: initialData?.registeredTradeName ?? '',
      companyWebsite: initialData?.companyWebsite ?? '',
      companyInfo: companyInfoInputValue,
      abn: initialData?.abn ?? '',
      realEstateLicenseNumber: initialData?.realEstateLicenseNumber ?? '',
      enquiryEmailAddress: initialData?.enquiryEmailAddress ?? '',
      businessNumber: initialData?.businessNumber ?? '',
      address: initialData?.address ?? '',
      suburb: suburbInputValue,
      postcode: postCode,
      state: state,
      salesPerson:
        initialData.salesPerson ??
        `${currentUser?.firstName} ${currentUser?.lastName}`,

      // row 3
      contactPrincipalName: initialData?.contactPrincipalName ?? '',
      contactPrincipalEmail: initialData?.contactPrincipalEmail ?? '',
      contactAccountPayableName: initialData?.contactAccountPayableName ?? '',
      contactAccountPayableEmail: initialData?.contactAccountPayableEmail ?? '',
      contactBillingAddress: contactBillingAddress,
      contactSuburb: suburbContactInputValue,
      contactPostcode: contactPostcode,
      contactState: contactState,
      crm: crmSales,

      crmSales: crmSales,
      crmRental: crmRental,

      contactSendListingOffice: initialData?.contactSendListingOffice ?? '',
      // row 4
      agencyState: initialData?.agencyState ?? '',
      brandColor: brandColor,
      logoUrl: logoUrl,
      coverImageUrl: coverImageUrl,
      //new fields
      docusignNote: initialData?.docusignNote ?? '',
      reaAgencyId: office,
      adminName: initialData?.adminName,
    },
    validationSchema: validationSchema,
  });

  const handleImage = (e, type) => {
    if (!e.target.files[0]) return null;
    let img = URL.createObjectURL(e.target.files[0]);

    if (type === 'logo') {
      setLogoUrl(img);
      handleUploadImage(e.target.files, type);
    }

    if (type === 'coverImage') {
      setCoverImageUrl(img);
      handleUploadImage(e.target.files, type);
    }

    setUploadTarget(type);
  };

  const handleUploadImage = (fileState, target) => {
    if (fileState) {
      var uploadData = new FormData();
      var files = fileState;
      for (const file of files) {
        uploadData.append('photo', file, file.name);
      }
      requestClearUploadImage();
      requestUploadImage(uploadData, target);
    }
  };

  useEffect(() => {
    if (uploadedImage && uploadTarget) {
      if (uploadTarget === 'logo') {
        setLogoUrl(uploadedImage);
      } else if (uploadTarget === 'coverImage') {
        setCoverImageUrl(uploadedImage);
      }
    }
  }, [uploadedImage]);

  useEffect(() => {
    if (qrFileFormat !== '' && qrDownloadLoading) {
      downloadComponentAsImage(
        qrCodeRef.current,
        `${initialData?.companyName} ${initialData?.referralCode}`,
        qrFileFormat,
        isSmall,
        isMedium,
        isLarge
      );
      setQrDownloadLoading(false);
    }
  }, [qrDownloadLoading, qrFileFormat]);

  const isHeadOffice = () => {
    const isParent =
      initialData.headAgencyReference === undefined ||
      initialData.headAgencyReference === null;
    return isParent;
  };

  const filterParentAgency = () => {
    const headAgencyId = initialData.headAgencyReference;

    if (agencies && agencies.length > 0) {
      const filter = (item) => item._id === headAgencyId;
      const filtered = agencies.filter(filter);
      if (filtered.length > 0) {
        return filtered[0].companyName;
      } else {
        return null;
      }
    }
  };

  const isDocusignSigned = () => {
    const isLinkSaved =
      initialData?.docusignDocumentLink &&
      `${initialData.docusignDocumentLink}`.length > 0;
    const isActualLink =
      initialData?.docusignDocumentLink &&
      `${initialData.docusignDocumentLink}`.includes('https') === true;
    const isSigned =
      initialData?.documentSigned === true &&
      Array.isArray(initialData?.agencyStateList) &&
      initialData.agencyStateList.includes('CONTRACT_SIGNED');

    return isLinkSaved && isActualLink && isSigned;
  };

  const isSubscriptionPaymentSet = () => {
    const isCardApplied =
      Array.isArray(initialData?.agencyStateList) &&
      initialData.agencyStateList.includes('SUBSCRIPTION_PAYMENT_SET') ===
        true &&
      Array.isArray(initialData?.subscription?.subscriptionStateList) &&
      initialData.subscription.subscriptionStateList.includes('PAYMENT_SET');

    const isPromoCodeApplied =
      Array.isArray(initialData?.agencyStateList) &&
      initialData.agencyStateList.includes('SUBSCRIPTION_DISCOUNT_APPLIED') ===
        true &&
      Array.isArray(initialData?.subscription?.subscriptionStateList) &&
      initialData.subscription.subscriptionStateList.includes(
        'DISCOUNT_APPLIED'
      );

    return isCardApplied || isPromoCodeApplied;
  };

  const checkSubscriptionStatus = (data) => {
    if (data?.subscription?.subscriptionStatus == false) {
      return 'suspended';
    } else {
      let pending_match = data?.agencyStateList?.filter(function (item) {
        return item === 'PENDING';
      });
      if (
        subscriptionStatuses.some((i) => data?.agencyStateList?.includes(i))
      ) {
        const checker = (value) =>
          subscriptionStatuses.some((element) => value.includes(element));
        let subscriptionData = [];
        subscriptionData = data?.agencyStateList.filter(checker);
        if (pending_match) {
          subscriptionData.push('PENDING');
        }
        const domainPrecedence = [
          'SUBSCRIPTION_CANCELED',
          'SUBSCRIPTION_ACTIVE',
          'SUBSCRIPTION_TRIAL_ACTIVE',
          'SUBSCRIPTION_PENDING',
          'PENDING',
        ];
        const sorted = subscriptionData.sort((a, b) => {
          let index1 = domainPrecedence.indexOf(a);
          let index2 = domainPrecedence.indexOf(b);
          return index1 == -1 ? 1 : index2 == -1 ? -1 : index1 - index2;
        });
        let subscriptionValue = '';

        if (sorted[0] === 'SUBSCRIPTION_PENDING') {
          subscriptionValue = 'pending_activation';
        } else if (sorted[0] === 'PENDING') {
          subscriptionValue = 'pending_approval';
        } else {
          subscriptionValue = sorted[0]
            ?.replace('SUBSCRIPTION_', '')
            ?.toLowerCase();
        }

        return subscriptionValue;
      }
    }
  };

  const checkSubPaymentStatus = (data) => {
    if (isHeadOffice()) {
      if (data?.subscription) {
        if (
          data?.subscription?.subscriptionStateList?.includes(
            'DISCOUNT_APPLIED'
          ) ||
          data?.subscription?.discount
        ) {
          let subscriptionValue = 'Promo Code Applied';
          return subscriptionValue;
        } else if (
          !data?.agencyStateList?.includes('ACTIVATED') &&
          data?.subscription?.subscriptionStateList?.includes('PAYMENT_SET')
        ) {
          let subscriptionValue = 'Credit Card Added';
          return subscriptionValue;
        } else if (
          !data?.subscription?.subscriptionStateList?.includes('PAYMENT_SET')
        ) {
          let subscriptionValue = 'Credit Card Pending';
          return subscriptionValue;
        }
      }
    }
  };

  const checkDocusignStatus = (data) => {
    if (isHeadOffice()) {
      if (docusignStatuses.some((i) => data?.agencyStateList?.includes(i))) {
        const checker = (value) =>
          docusignStatuses.some((element) => value.includes(element));
        let docusignData = [];
        docusignData = data?.agencyStateList.filter(checker);

        if (
          data?.docusignDocumentLink &&
          `${data.docusignDocumentLink}`.includes('https') === true
        ) {
          if (
            data.agencyStateList.includes('CONTRACT_RESEND') ||
            data.agencyStateList.includes('CONTRACT_REJECTED') ||
            data.agencyStateList.includes('CONTRACT_STALE')
          ) {
            return 'resend_docusign';
          } else if (
            docusignData.includes('CONTRACT_PENDING') &&
            docusignData.includes('CONTRACT_PENDING') &&
            data.docusignDocumentLink
          ) {
            return 'docusign_resent';
          } else if (
            docusignData.includes('CONTRACT_PENDING') &&
            docusignData.includes('CONTRACT_SENT')
          ) {
            return 'docusign_sent';
          } else if (
            docusignData.includes('CONTRACT_SENT') &&
            docusignData.includes('CONTRACT_SIGNED')
          ) {
            return 'docusign_signed';
          } else {
            return docusignData[0]
              ?.replace('CONTRACT_', 'DOCUSIGN_')
              ?.toLowerCase();
          }
        } else {
          return 'docusign_pending';
        }
      } else {
        return 'docusign_pending';
      }
    }
  };

  const checkSubscriptionPaymentStatus = (data) => {
    if (
      subscriptionPaymentStatuses.some((i) =>
        data?.agencyStateList?.includes(i)
      )
    ) {
      const checker = (value) =>
        subscriptionPaymentStatuses.some((element) => value.includes(element));
      let paymentData = [];
      paymentData = data?.agencyStateList.filter(checker);

      let paymentValue = '';
      if (paymentData[0] === 'SUBSCRIPTION_PAYMENT_PENDING') {
        paymentValue = 'Subscription charge Pending';
      } else if (paymentData[0] === 'SUBSCRIPTION_PAYMENT_CHARGED') {
        paymentValue = 'Subscription Charged';
      } else if (paymentData[0] === 'SUBSCRIPTION_PAYMENT_FAILED') {
        paymentValue = 'Subscription payment failed';
      } else {
      }

      return paymentValue;
    }
  };

  const checkInvoicePaymentStatus = (data) => {
    let invoiceValue = '';
    if (
      invoicePaymentStatuses.some((i) => data?.agencyStateList?.includes(i))
    ) {
      const checker = (value) =>
        invoicePaymentStatuses.some((element) => value.includes(element));

      let invoiceData = [];
      invoiceData = data?.agencyStateList.filter(checker);

      const domainPrecedence = [
        'INVOICE_PAID',
        'INVOICE_REMINDER_SENT',
        'INVOICE_DUE',
        'INVOICE_ISSUED',
      ];

      const sorted = invoiceData.sort((a, b) => {
        let index1 = domainPrecedence.indexOf(a);
        let index2 = domainPrecedence.indexOf(b);
        return index1 == -1 ? 1 : index2 == -1 ? -1 : index1 - index2;
      });

      invoiceValue = sorted[0]?.replace('INVOICE_', '')?.toLowerCase();
    }
    return invoiceValue;
  };

  // statuses
  let subScriptionStatus = checkSubscriptionStatus(initialData) || '';
  let subPaymentStatus = checkSubPaymentStatus(initialData) || '';
  let docusignStatus = checkDocusignStatus(initialData) || '';
  let subscriptionPaymentStatus =
    checkSubscriptionPaymentStatus(initialData) || '';
  let invoicePaymentStatus = checkInvoicePaymentStatus(initialData) || '';

  const shouldRequestCreditCardStatus = () => {
    const isSigned = isDocusignSigned();
    const isSubscribed = isSubscriptionPaymentSet();

    if (isSigned) {
      if (isSubscribed) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const shouldDisableActivateButton = () => {
    const isActivated =
      (initialData?.agencyState === 'APPROVED' ||
        initialData?.agencyState === 'ACTIVATED') &&
      initialData?.agencyStateList.includes('ACTIVATED');

    if (isActivated) return true;

    return false;
  };

  const handleCheckboxChange = () => {
    let office_address = formik.values.address;

    // For autocomplete checkbox
    setContactSuburbAutoCompleteValue(suburb.locality || formik.values.suburb);

    setContactBillingAddress(office_address);
    setSuburbContactInputValue(suburb.locality || formik.values.suburb);
    setContactPostcode(postCode);
    setContactState(state);

    formik.values['contactBillingAddress'] = contactBillingAddress;
    formik.values['contactSuburb'] = suburb.locality || formik.values.suburb;
    formik.values['contactPostcode'] = postCode;
    formik.values['contactState'] = state;
  };

  const checkPackageChange = () => {
    return (
      `${initialData?.subscription?.subscriptionType}-${initialData?.subscription?.billingPeriodType}` ===
      stripePackage
    );
  };

  const onSendDocusignHandler = () => {
    setShowDocusignPreview(false);
    handleConfirmDialogOpen({
      open: true,
      type: 'send_docusign',
      title: 'Send Docusign',
      message: 'Are you sure you want to send docusign to agency?',
      data: formik.values,
    });
  };

  const onCompanyInfoChangeHandler = (value) => {
    if (!value) {
      setIsCompanyInfoError(true);
    } else {
      setIsCompanyInfoError(false);
    }

    setCompanyInfoInputValue(value);
  };

  const _renderInfo = () => {
    return (
      <Paper className={classes.formContainer}>
        <Grid container spacing={2} justifyContent={'space-between'}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <div className={classes.form}>
              <form onSubmit={formik.handleSubmit}>
                {AdminInfoFieldA.map((value, index) => {
                  return (
                    <>
                      {value.name === 'phone' ? (
                        <InputMask
                          mask={'9999999999'}
                          value={formik.values[value.name]}
                          onChange={formik.handleChange}
                          maskPlaceholder={null}
                          onBlur={formik.handleBlur}
                        >
                          <FormInput
                            type='tel'
                            key={index}
                            fullWidth
                            id={value.name}
                            name={value.name}
                            label={value.label}
                            variant='outlined'
                            error={
                              (formik.touched[value.name] ||
                                (refError && refError[value.name])) &&
                              Boolean(formik.errors[value.name])
                            }
                            helperText={
                              formik.touched[value.name] &&
                              formik.errors[value.name]
                            }
                          />
                        </InputMask>
                      ) : (
                        <FormInput
                          value={formik.values[value.name]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          type={value.name === 'phone' ? 'tel' : 'text'}
                          key={index}
                          fullWidth
                          id={value.name}
                          name={value.name}
                          label={value.label}
                          variant='outlined'
                          error={
                            (formik.touched[value.name] ||
                              (refError && refError[value.name])) &&
                            Boolean(formik.errors[value.name])
                          }
                          helperText={
                            formik.touched[value.name] &&
                            formik.errors[value.name]
                          }
                        />
                      )}
                    </>
                  );
                })}
                <Box marginTop='10px'>
                  <CustomSelect
                    styles={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      },
                    }}
                    isForm={true}
                    label='Package'
                    options={packageOptions}
                    handleSelect={handleStripePackage}
                    isClearFilter={false}
                    value={stripePackage ?? null}
                    isLoading={isLoading}
                    placeholder={
                      <Box style={{ marginTop: '20px' }}>
                        -- Please Select --
                      </Box>
                    }
                  />
                </Box>
                <Box marginTop='10px'>
                  <CustomSelect
                    styles={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      },
                    }}
                    isForm={true}
                    label='Period'
                    options={PERIOD}
                    handleSelect={handleStripePackagePeriod}
                    isClearFilter={false}
                    value={stripePackagePeriod ?? null}
                    isLoading={isLoading}
                    placeholder={
                      <Box style={{ marginTop: '20px' }}>
                        -- Please Select --
                      </Box>
                    }
                  />
                </Box>

                <Box marginTop='10px'>
                  {!checkPackageChange() ? (
                    <Box>
                      <CustomDatePicker
                        isForm={true}
                        name='effectiveDate'
                        label='Effective Date'
                        value={effectiveDate ?? ''}
                        handleDate={handleEffectiveDate}
                        isClearFilter={false}
                        //minDate={today}
                      />

                      {subscriptionExpiration ? (
                        <Box margin='-5px 0px 5px 5px'>
                          <Typography
                            style={{
                              fontSize: '12px',
                              color: '#808080',
                            }}
                          >
                            Current subscription will expire in
                            <span
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {' '}
                              {subscriptionExpiration}
                            </span>
                            {`${
                              subscriptionExpiration === 1 ? ' day' : ' days'
                            }`}
                          </Typography>
                        </Box>
                      ) : null}
                    </Box>
                  ) : null}
                </Box>
                <>
                  <CustomDatePicker
                    isForm={true}
                    name='startDate'
                    label='Start Date'
                    value={startDate ?? ''}
                    handleDate={handleStartDate}
                    isClearFilter={false}
                    //minDate={today}
                  />
                  <CustomDatePicker
                    isForm={true}
                    name='billingDate'
                    label='Billing Date'
                    value={billingDate ?? ''}
                    handleDate={handleBillingDate}
                    isClearFilter={false}
                    //minDate={today}
                  />
                </>
                <Box marginTop='10px'>
                  {/*office && officeId === id ? (
                    <>
                      <CustomSelect
                        styles={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          },
                        }}
                        isForm={true}
                        label="Office"
                        options={officesOptions}
                        handleSelect={handleOffice}
                        isClearFilter={false}
                        value={office ? office : 'pleaseSelect'}
                        renderValue={(field) => {
                          const renderedOfficeSelectLabel = handleGetOfficeLabel(office);

                          return office ? (
                            <Box>{renderedOfficeSelectLabel ? renderedOfficeSelectLabel : '-- Please Select --'}</Box>
                          ) : (
                            <Box>-- Please Select --</Box>
                          );
                        }}
                        isLoading={isLoading}
                      />
                      <Link
                        target="_blank"
                        href="https://docs.google.com/spreadsheets/d/1lCkqYszmKOkarFQj51IoUw9kCCbf5KGgz1dzLzNB418/edit?pli=1#gid=699705216 "
                      >
                        Agency List with Office Ids
                      </Link>
                    </>
                      ) : null*/}

                  <FormInput
                    fullWidth
                    disabled
                    id='officeId'
                    name='officeId'
                    label='Office Id'
                    variant='outlined'
                    value={officeId ?? ''}
                  />

                  {!isHeadOffice() ? (
                    <>
                      <FormInput
                        fullWidth
                        id='headOfficeId'
                        name='headOfficeId'
                        label='Suboffice of'
                        variant='outlined'
                        disabled={true}
                        value={filterParentAgency() ?? ''}
                      />
                    </>
                  ) : null}
                </Box>
              </form>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <div className={classes.form}>
              <form onSubmit={formik.handleSubmit}>
                {AdminInfoFieldB.map((value, index) => {
                  return (
                    <div key={index}>
                      {(() => {
                        if (value.name === 'abn') {
                          return (
                            <InputMask
                              mask={'99999999999'}
                              value={formik.values[value.name]}
                              onChange={formik.handleChange}
                              maskPlaceholder={null}
                              onBlur={formik.handleBlur}
                            >
                              <FormInput
                                type='tel'
                                key={index}
                                fullWidth
                                id={value.name}
                                name={value.name}
                                label={value.label}
                                variant='outlined'
                                value={formik.values[value.name]}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={
                                  (formik.touched[value.name] ||
                                    (refError && refError[value.name])) &&
                                  Boolean(formik.errors[value.name])
                                }
                                helperText={
                                  formik.touched[value.name] &&
                                  formik.errors[value.name]
                                }
                              />
                            </InputMask>
                          );
                        } else if (value.name == 'suburb') {
                          return (
                            <SuburbSearchField
                              key={index}
                              name='suburb'
                              value={suburb ?? ''}
                              defaultValue={{
                                locality:
                                  initialData?.suburb?.toUpperCase() ?? '',
                              }}
                              inputValue={suburbInputValue}
                              setValueCallback={setSuburb}
                              open={openSuburbList}
                              setOpen={setOpenSuburbList}
                              placeholder={'Suburb *'}
                              onInputChange={(e, value) => {
                                if (value) {
                                  setSuburbInputValue(value);
                                } else {
                                  setOpenSuburbList(false);
                                }
                              }}
                            />
                          );
                        } else if (value.name == 'companyInfo') {
                          return (
                            <CompanyInfoSearchField
                              value={companyInfoInputValue}
                              isErrorShown={isCompanyInfoError}
                              onValueChange={onCompanyInfoChangeHandler}
                            />
                          );
                        } else {
                          return (
                            <FormInput
                              type={'text'}
                              key={index}
                              fullWidth
                              id={value.name}
                              name={value.name}
                              label={value.label}
                              variant='outlined'
                              value={formik.values[value.name]}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              error={
                                (formik.touched[value.name] ||
                                  (refError && refError[value.name])) &&
                                Boolean(formik.errors[value.name])
                              }
                              helperText={
                                formik.touched[value.name] &&
                                formik.errors[value.name]
                              }
                            />
                          );
                        }
                      })()}
                    </div>
                  );
                })}
                <>
                  <Box marginTop='10px' display='flex'>
                    <Box width='40%' marginTop='-17.5px' marginRight='20px'>
                      <InputMask
                        mask={'99999999999'}
                        value={postCode ?? ''}
                        onChange={(event) => {
                          setPostCode(event.target.value);
                        }}
                        maskPlaceholder={null}
                      >
                        <FormInput
                          fullWidth
                          id='postcode'
                          name='postcode'
                          label='Postcode *'
                          variant='outlined'
                        />
                      </InputMask>
                    </Box>
                    <Box width='60%'>
                      <InputLabel
                        variant='standard'
                        style={{
                          color: 'gray',
                          fontSize: '0.75rem',
                          marginBottom: '-10px',
                        }}
                      >
                        State *
                      </InputLabel>
                      <SelectField
                        options={STATES}
                        placeholder={'State'}
                        value={state ?? ''}
                        onChange={(e, value) => {
                          //var value = e.target.value;
                          setState(value);
                          // if (value) {
                          //   handleCheckField(value, value, 'state')
                          // }
                        }}
                        onBlur={(e, value) => {
                          //var value = e.target.value;
                          setState(value);
                          // handleCheckField(value, value, 'state')
                        }}
                      />
                    </Box>
                  </Box>

                  <Button
                    className={classes.brandingButton}
                    fullWidth
                    variant='contained'
                    disableElevation
                    onClick={() => {
                      setActiveModal(true);
                    }}
                  >
                    Edit Branding Info
                  </Button>
                </>
              </form>
            </div>
          </Grid>
        </Grid>
        <FormModal className={activeModal ? 'active' : ''}>
          <IconButton
            aria-label='close'
            className={classes.closeicon}
            onClick={() => {
              setActiveModal(false);
            }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
          <div className='modal-wrap'>
            <div>
              <h2>Edit Agency Info</h2>
              <div className='form-wrap'>
                <div className='form-field'>
                  <label>Brand Color</label>
                  <input
                    type='color'
                    value={brandColor ?? ''}
                    onChange={(e) => setBrandColor(e.target.value)}
                    style={{ width: 'auto' }}
                  />
                </div>
              </div>
              <div className='form-wrap'>
                <div className='form-field'>
                  <label>Agency Logo</label>
                  {logoUrl ? (
                    <img className={classes.imgLogo} src={logoUrl} />
                  ) : (
                    <div className='logo-label'>
                      <label>Please upload a Logo</label>
                    </div>
                  )}
                </div>
                <div>
                  <label
                    className={classes.changeImageButton}
                    htmlFor='profile-logo-upload'
                  >
                    + Edit profile logo{' '}
                  </label>
                  <input
                    id='profile-logo-upload'
                    type='file'
                    onChange={(e) => {
                      handleImage(e, 'logo');
                    }}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
              <div className='form-wrap'>
                <div className='form-field'>
                  <label>Agency Banner</label>
                  <img className={classes.imgBanner} src={coverImageUrl} />
                </div>
                <div>
                  <label
                    className={classes.changeImageButton}
                    htmlFor='banner-image-upload'
                  >
                    + Edit banner image{' '}
                  </label>
                  <input
                    id='banner-image-upload'
                    type='file'
                    onChange={(e) => {
                      handleImage(e, 'coverImage');
                    }}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
            </div>
            <div className='button-wrap'>
              <button
                className={classes.primaryBtn}
                onClick={() => {
                  setActiveModal(false);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </FormModal>
      </Paper>
    );
  };

  const _renderOptInOutReferral = () => {
    return (
      <Paper style={{ marginTop: 15 }} className={classes.formContainer}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box display='flex'>
            <Typography sx={{ mt: 10 }}>Referral Program</Typography>
            <HelpOutline style={{ height: 19, marginTop: 2, marginLeft: 5 }} />
          </Box>
        </Box>
        {initialData?.referralCode ? (
          <Box mt={2}>
            <Typography style={{ fontSize: 10, color: '#4D4D4D' }}>
              Copy Referral Code
            </Typography>
            <TextField
              fullWidth
              variant='outlined'
              value={initialData?.referralCode || ''}
              onTouchMove={handlePreventCutCopyPaste}
              onCut={handlePreventCutCopyPaste}
              onCopy={handlePreventCutCopyPaste}
              onPaste={handlePreventCutCopyPaste}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment
                    position='end'
                    style={{
                      cursor: 'pointer',
                      border: '2px solid #E0E0E0',
                      color: '#35C0CA',
                    }}
                    onClick={handleCopyClipboard}
                  >
                    <FileCopyOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        ) : (
          <>
            <Box display='flex' flexDirection='column'>
              <FormControlLabel
                style={{
                  color: 'gray',
                  fontFamily: ' "Roboto", "Helvetica", "Arial", "sans-serif"',
                }}
                control={
                  <Checkbox
                    style={{ color: '#35C0CA' }}
                    onChange={(e) => setIppReferralCheckbox(e.target.checked)}
                    checked={ippReferralCheckbox}
                  />
                }
                label={
                  <Typography style={{ fontSize: 13 }}>
                    Activate Referral Program
                  </Typography>
                }
              />
            </Box>
            <Box mt={1}>
              <Button
                size='medium'
                disabled={!ippReferralCheckbox}
                onClick={() =>
                  dispatch(
                    requestPOSTAPIAgencyReferral(
                      { query: initialData?._id },
                      'IPP_ANALYTICS_GENERATE_REFERRAL_CODE'
                    )
                  )
                }
                style={{
                  marginRight: 5,
                  border: ippReferralCheckbox ? '2px solid #35C0CA' : '',
                  borderRadius: 40,
                  height: 50,
                  color: ippReferralCheckbox ? '#35C0CA' : '',
                }}
                variant='outlined'
                fullWidth
                // type="submit"
                disableElevation
              >
                Generate Referral Code
              </Button>
            </Box>
          </>
        )}
      </Paper>
    );
  };
  const _renderContacts = () => {
    return (
      <>
        <Paper className={classes.formContainer}>
          <Grid container spacing={2} justifyContent={'space-between'}>
            <Grid item xs={12} md={12} lg={12}>
              <div className={classes.form}>
                <form onSubmit={formik.handleSubmit}>
                  {AdminInfoFieldC.map((value, index) => {
                    return (
                      <div key={index}>
                        {(() => {
                          if (value.name == 'contactSuburb') {
                            return (
                              <SuburbSearchField
                                key={index}
                                name='contactSuburb'
                                value={contactSuburb ?? ''}
                                defaultValue={{
                                  locality:
                                    initialData?.contactSuburb?.toUpperCase() ??
                                    '',
                                }}
                                autoCompleteValue={
                                  contactSuburbAutoCompleteValue
                                    ? contactSuburbAutoCompleteValue
                                    : ''
                                }
                                inputValue={suburbContactInputValue}
                                setValueCallback={setContactSuburb}
                                open={openContactSuburbList}
                                setOpen={setOpenContactSuburbList}
                                placeholder={'Suburb'}
                                onInputChange={(e, value) => {
                                  setIsSameAddress(false);
                                  if (value) {
                                    setContactSuburbAutoCompleteValue(value);
                                    setSuburbContactInputValue(value);
                                  } else {
                                    setOpenContactSuburbList(false);
                                  }
                                }}
                              />
                            );
                          } else if (value.name == 'contactBillingAddress') {
                            return (
                              <>
                                <FormInput
                                  fullWidth
                                  id='contactBillingAddress'
                                  name='contactBillingAddress'
                                  label='Billing Address'
                                  variant='outlined'
                                  value={contactBillingAddress}
                                  onBlur={formik.handleBlur}
                                  onChange={(e) => {
                                    setIsSameAddress(false);
                                    formik.handleChange(e);
                                    setContactBillingAddress(e.target.value);
                                  }}
                                  error={
                                    (formik.touched['contactBillingAddress'] ||
                                      (refError &&
                                        refError['contactBillingAddress'])) &&
                                    Boolean(
                                      formik.errors['contactBillingAddress']
                                    )
                                  }
                                  helperText={
                                    (formik.touched['contactBillingAddress'] ||
                                      (refError &&
                                        refError['contactBillingAddress'])) &&
                                    formik.errors['contactBillingAddress']
                                  }
                                />
                                <FormControlLabel
                                  style={{
                                    color: 'gray',
                                    fontFamily:
                                      ' "Roboto", "Helvetica", "Arial", "sans-serif"',
                                  }}
                                  control={
                                    <Checkbox
                                      checked={isSameAddress}
                                      style={{ color: '#35C0CA' }}
                                      onChange={(event) => {
                                        setIsSameAddress(event.target.checked);
                                        if (event.target.checked) {
                                          handleCheckboxChange();
                                        }
                                      }}
                                    />
                                  }
                                  label='Same as office address'
                                />
                              </>
                            );
                          } else {
                            return (
                              <FormInput
                                key={index}
                                fullWidth
                                id={value.name}
                                name={value.name}
                                label={value.label}
                                variant='outlined'
                                value={formik.values[value.name]}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={
                                  (formik.touched[value.name] ||
                                    (refError && refError[value.name])) &&
                                  Boolean(formik.errors[value.name])
                                }
                                helperText={
                                  formik.touched[value.name] &&
                                  formik.errors[value.name]
                                }
                              />
                            );
                          }
                        })()}
                      </div>
                    );
                  })}
                  <>
                    <Box marginTop='10px' display='flex'>
                      <Box width='40%' marginTop='-17.5px' marginRight='20px'>
                        <InputMask
                          mask={'99999999999'}
                          value={contactPostcode ?? ''}
                          onChange={(event) => {
                            setIsSameAddress(false);
                            setContactPostcode(event.target.value);
                          }}
                          // onBlur={(event) => console.debug('focusing out')}
                          maskPlaceholder={null}
                        >
                          <FormInput
                            fullWidth
                            id='contactPostcode'
                            name='contactPostcode'
                            label='Postcode'
                            variant='outlined'
                          />
                        </InputMask>
                      </Box>
                      <Box width='60%'>
                        <InputLabel
                          variant='standard'
                          style={{
                            color: 'gray',
                            fontSize: '0.75rem',
                            marginBottom: '-10px',
                          }}
                        >
                          State *
                        </InputLabel>
                        <SelectField
                          options={STATES}
                          placeholder={'State'}
                          value={contactState ?? ''}
                          onChange={(e, value) => {
                            setIsSameAddress(false);
                            //var value = e.target.value;
                            setContactState(value);
                          }}
                          onBlur={(e, value) => {
                            //var value = e.target.value;
                            setContactState(value);
                            // handleCheckField(value, value, 'state')
                          }}
                        />
                      </Box>
                    </Box>

                    {/* <Typography
                      style={{
                        marginTop: '18px',
                        lineHeight: '0px',
                        fontSize: '0.75rem',
                        color: 'gray',
                      }}
                    >
                      CRM *
                    </Typography>
                    <SelectField
                      options={CRMS.sort()}
                      placeholder={
                        'Your Bulk Uploader Provider - who you upload listings through *'
                      }
                      value={manualCrm ?? ''}
                      // errorMsg={errors.crm.message}
                      // showError={errors.crm.status}
                      onChange={(e, value) => {
                        //var value = e.target.value;
                        if (value && value !== 'Other') {
                          setManualCrm(value)
                          // handleCheckField(value, value, 'crm')
                        } else {
                          setManualCrm(value)
                        }
                      }}
                      onBlur={(e, value) => {
                        //var value = e.target.value;
                        if (value && value !== 'Other') {
                          setManualCrm(value)
                          // handleCheckField(value, value, 'crm')
                        } else {
                          setManualCrm(value)
                        }
                      }}
                      style={{ marginTop: 0, marginBottom: 18 }}
                    /> */}
                    <FormInput
                      fullWidth
                      id='contactSendListingOffice'
                      name='contactSendListingOffice'
                      label='Which offices do you want to send listing from?'
                      variant='outlined'
                      value={formik.values.contactSendListingOffice}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        (formik.touched['contactSendListingOffice'] ||
                          (refError && refError['contactSendListingOffice'])) &&
                        Boolean(formik.errors['contactSendListingOffice'])
                      }
                      helperText={
                        formik.touched['contactSendListingOffice'] &&
                        formik.errors['contactSendListingOffice']
                      }
                    />
                    <Typography
                      style={{
                        marginTop: '18px',
                        lineHeight: '0px',
                        fontSize: '0.75rem',
                        color: 'gray',
                      }}
                    >
                      Sales Listing Uploader
                    </Typography>
                    <SelectField
                      options={CRMS.sort()}
                      placeholder={
                        'Your Bulk Uploader Provider - who you upload sales listings through *'
                      }
                      value={manualCrmSales}
                      // errorMsg={errors.crm.message}
                      // showError={errors.crm.status}
                      onChange={(e, value) => {
                        setIsSameAsListingProvider(false);
                        //var value = e.target.value;
                        if (value && value !== 'Other') {
                          setManualCrmSales(value);
                          setCrmSales(value);
                          //handleRequiredField(value, 'crmSales')
                        } else {
                          setManualCrmSales(value);
                          setCrmSales('');
                        }
                      }}
                      onBlur={(e, value) => {
                        //var value = e.target.value;
                        if (value && value !== 'Other') {
                          setManualCrmSales(value);
                          setCrmSales(value);
                          // handleCheckField(value, value, 'crm')
                          //handleRequiredField(value, 'crmSales')
                        } else {
                          setManualCrmSales(value);
                          setCrmSales('');
                        }
                      }}
                      style={{ marginTop: 0, marginBottom: 18 }}
                    />
                    {manualCrmSales &&
                      manualCrmSales !== '' &&
                      manualCrmSales === 'Other' && (
                        <FormInput
                          type={'text'}
                          value={crmSales}
                          placeholder={'Sales Listing Uploader *'}
                          onChange={(e) => {
                            var value = e.target.value;
                            setCrmSales(value);
                            /*                       if (value) {
                        handleRequiredField(value, 'crmSales')
                      } */
                          }}
                          onBlur={(e) => {
                            var value = e.target.value;
                            setCrmSales(value);
                            //handleRequiredField(value, 'crmSales')
                          }}
                          /*                           error={errors?.crmSales?.status}
                      errorMessage={errors?.crmSales?.message} */
                        />
                      )}
                    <Typography
                      style={{
                        marginTop: '18px',
                        lineHeight: '0px',
                        fontSize: '0.75rem',
                        color: 'gray',
                      }}
                    >
                      Rental Listing Uploader
                    </Typography>

                    <SelectField
                      options={CRMS.sort()}
                      placeholder={
                        'Your Bulk Uploader Provider - who you upload rental listings through *'
                      }
                      value={manualCrmRental}
                      // errorMsg={errors.crm.message}
                      // showError={errors.crm.status}
                      onChange={(e, value) => {
                        setIsSameAsListingProvider(false);
                        //var value = e.target.value;
                        if (value && value !== 'Other') {
                          setManualCrmRental(value);
                          setCrmRental(value);
                          //handleRequiredField(value, 'crmRental')
                        } else {
                          setManualCrmRental(value);
                          setCrmRental('');
                        }
                      }}
                      onBlur={(e, value) => {
                        //var value = e.target.value;
                        if (value && value !== 'Other') {
                          setManualCrmRental(value);
                          setCrmRental(value);
                          // handleCheckField(value, value, 'crm')
                          //handleRequiredField(value, 'crmRental')
                        } else {
                          setManualCrmRental(value);
                          setCrmRental('');
                        }
                      }}
                      style={{ marginTop: 0, marginBottom: 18 }}
                    />
                    {manualCrmRental &&
                      manualCrmRental !== '' &&
                      manualCrmRental === 'Other' && (
                        <FormInput
                          type={'text'}
                          value={crmRental}
                          placeholder={'Rental Listing Uploader *'}
                          onChange={(e) => {
                            setIsSameAsListingProvider(false);
                            var value = e.target.value;
                            setCrmRental(value);
                            /*                         if (value) {
                          handleRequiredField(value, 'crmRental')
                        } */
                          }}
                          onBlur={(e) => {
                            var value = e.target.value;
                            setCrmRental(value);
                            //handleRequiredField(value, 'crmRental')
                          }}
                          /*                           error={errors?.crmRental?.status}
                      errorMessage={errors?.crmRental?.message} */
                        />
                      )}
                    <FormControlLabel
                      style={{
                        color: 'gray',
                        fontFamily:
                          ' "Roboto", "Helvetica", "Arial", "sans-serif"',
                      }}
                      control={
                        <Checkbox
                          checked={isSameAsListingProvider}
                          style={{ color: '#35C0CA' }}
                          onChange={(event) => {
                            setIsSameAsListingProvider(
                              !isSameAsListingProvider
                            );
                            if (manualCrmSales && manualCrmSales !== 'Other') {
                              setManualCrmRental(crmSales);
                              setCrmRental(crmSales);
                            } else {
                              setManualCrmRental('Other');
                              setCrmRental(crmSales);
                            }
                          }}
                        />
                      }
                      label='Same as Sales Listing Uploader'
                    />
                  </>
                </form>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.saveButton}
            fullWidth
            color={'primary'}
            variant='outlined'
            // type="submit"
            onClick={() => {
              let CRM = crmSales === 'Other' ? manualCrmSales : crmSales;
              let CRM_SALES = crmSales === 'Other' ? manualCrmSales : crmSales;
              let CRM_RENT =
                crmRental === 'Other' ? manualCrmRental : crmRental;

              let form_values = formik.values;
              form_values.contactBillingAddress = contactBillingAddress;
              form_values.subscription.startDateString = startDate;
              form_values.subscription.billingDateString = billingDate;
              form_values.subscription.effectiveDateString = effectiveDate;
              form_values.subscription.trialPeriodDayCount =
                trialPeriodDayCount;
              form_values.subscription.type = stripePackage;
              form_values.companyInfo = companyInfoInputValue;
              form_values.suburb = suburbInputValue;
              form_values.postcode = postCode;
              form_values.state = state;
              form_values.contactSuburb = suburbContactInputValue;
              form_values.contactPostcode = contactPostcode;
              form_values.contactState = contactState;
              form_values.crm = CRM;
              form_values.crmSales = CRM_SALES;
              form_values.crmRental = CRM_RENT;
              form_values.brandColor = brandColor;
              form_values.logoUrl = logoUrl;
              form_values.coverImageUrl = coverImageUrl;
              form_values.reaAgencyId = officeId;
              if (Object.keys(formik.errors).length === 0) {
                handleConfirmDialogOpen({
                  open: true,
                  type: 'save_draft',
                  title: 'Save Account Draft',
                  message:
                    'Are you sure you want to save agency account details?',
                  data: formik.values,
                });
              } else {
                formik.validateForm().then((errors) => {
                  handleConfirmDialogOpen({
                    open: true,
                    type: 'save_draft',
                    title: 'Save Account Draft',
                    message:
                      'Are you sure you want to save agency account details?',
                    data: formik.values,
                  });
                });
              }
            }}
          >
            Save
          </Button>
        </div>
      </>
    );
  };

  const _renderOptions = () => {
    return (
      <Paper className={classes.optionContainer}>
        <Typography className={classes.title} variant={'h1'}>
          Account Status
        </Typography>
        <SuperStatusAgency type={subScriptionStatus} fullWidth />
        <SuperStatusAgency type={subPaymentStatus} fullWidth />
        <Grid
          className={classes.buttonOptionsContainer}
          container
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid item xs={12}>
            <ButtonOptions
              disabled={shouldDisableActivateButton()}
              label={'Activate Account'}
              onClick={() => {
                if (initialData?.agencyState !== 'ACTIVATED') {
                  handleConfirmDialogOpen({
                    open: true,
                    type: 'activate_account',
                    title: 'Activate Account',
                    message:
                      'Are you sure you want to send email activation for the agency account?',
                    //password: formik.values.password,
                  });
                }
              }}
              icon={
                <div
                  className={classes.buttonIcon}
                  style={
                    !shouldDisableActivateButton()
                      ? { backgroundColor: '#20bf60', color: '#FFF' }
                      : { backgroundColor: '#dcdcdc', color: '#FFF' }
                  }
                >
                  <PowerSettingsNewIcon className={classes.icon} />
                </div>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonOptions
              label={'Pause Account'}
              disabled={true}
              onClick={() => {
                if (initialData?.agencyStatus) {
                  handleConfirmDialogOpen({
                    open: true,
                    type: 'pause_account',
                    title: 'Pause Account',
                    message: 'Are you sure you want to pause agency account?',
                  });
                }
              }}
              icon={
                <div
                  className={classes.buttonIcon}
                  style={{ backgroundColor: '#dcdcdc', color: '#FFF' }}
                >
                  <PauseCircleOutlineIcon className={classes.icon} />
                </div>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonOptions
              label={'Delete Account'}
              disabled={false}
              onClick={() =>
                handleConfirmDialogOpen({
                  open: true,
                  type: 'delete_account',
                  title: 'Delete Account',
                  message: 'Are you sure you want to delete agency account?',
                })
              }
              icon={
                <div
                  className={classes.buttonIcon}
                  style={{ backgroundColor: '#E91E63', color: '#FFF' }}
                  //style={{ backgroundColor: '#dcdcdc', color: '#FFF' }}
                >
                  <DeleteIcon className={classes.icon} />
                </div>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonOptions
              label={'Manage Agents'}
              icon={
                <div
                  className={classes.buttonIcon}
                  style={{ backgroundColor: '#FFF', color: '#6a6a6a' }}
                >
                  <SettingsIcon className={classes.icon} />
                </div>
              }
              onClick={() =>
                routerChange(
                  '/superadmin/manage-agencies/' + match.params.id + '/agents'
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonOptions
              label={'Manage Admins'}
              icon={
                <div
                  className={classes.buttonIcon}
                  style={{ backgroundColor: '#FFF', color: '#6a6a6a' }}
                >
                  <AccountCircleIcon className={classes.icon} />
                </div>
              }
              onClick={() =>
                routerChange(
                  '/superadmin/manage-agencies/' + match.params.id + '/admins'
                )
              }
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const _renderSubscriptions = () => {
    return (
      <Paper className={classes.optionContainer}>
        <Typography className={classes.title} variant={'h1'}>
          Subscription Status
        </Typography>
        <Grid
          className={classes.buttonOptionsContainer}
          container
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid item xs={12}>
            <ButtonOptions
              label={subScriptionStatus}
              onClick={() => {}}
              icon={
                <SuperStatusIcon
                  status={subScriptionStatus}
                  type='subscription'
                />
              }
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const _renderDocusigns = () => {
    return (
      <Paper className={classes.optionContainer}>
        <Typography className={classes.title} variant={'h1'}>
          Docusign Status
        </Typography>
        <div className={classes.docusignStatusChip}>
          <SuperStatusAgency type={docusignStatus} fullWidth />
        </div>
      </Paper>
    );
  };

  const _renderIppReferralSection = () => {
    return (
      <Paper className={classes.ippReferralContainer}>
        <Typography className={classes.title} variant='h1'>
          Referral Report
        </Typography>
        <FormControl
          variant='outlined'
          fullWidth
          size='small'
          margin='normal'
          className={classes.formControl}
        >
          <Typography fontSize={14}>Account Status</Typography>
          <Select
            value={ippSelectDate}
            onChange={(e) => setIppSelectDate(e.target.value)}
            style={{ marginTop: 4, borderRadius: 10, width: '100%' }}
          >
            <MenuItem value='TODAY'>
              <em>Today</em>
            </MenuItem>
            <MenuItem value='LAST7DAYS'>Last 7 Days</MenuItem>
            <MenuItem value='LAST30DAYS'>Last 30 Days</MenuItem>
            <MenuItem value='CUSTOM'>Custom</MenuItem>
          </Select>
        </FormControl>
        {ippSelectDate === 'CUSTOM' && (
          <>
            <ReferralDatePicker
              label='From'
              selectedDate={ippStartDate}
              handleDateChange={handleStartDateChange}
            />
            <ReferralDatePicker
              label='To'
              selectedDate={ippEndDate}
              minDate={ippStartDate}
              handleDateChange={handleEndDateChange}
              disabled={!ippStartDate || ippStartDate === null}
            />
          </>
        )}
        <Card
          variant='outlined'
          display='flex'
          flexDirection='column'
          style={{ padding: 20, borderRadius: 8 }}
        >
          {ippReferralAnalytics.map((item, index) =>
            referralLoading ? (
              <Skeleton key={index} style={{ padding: 0, margin: 0 }}>
                <ReferralDataCards width={300} height={150} />
              </Skeleton>
            ) : (
              <ReferralDataCards
                textData={
                  item.title === 'Bounce Rate'
                    ? roundOffTwoDecimal(item.data)
                    : item.data
                }
                textLabel={item.title}
                marginTop={index === 0 ? 0 : 15}
              />
            )
          )}
        </Card>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
          mt={2}
          id='qrCodeEl'
        >
          <Typography style={{ marginBottom: 5 }}>Your QR Code</Typography>
          <QRCodeCanvas value={referralCodeLink} />
          {/* <Box
            marginTop={3}
            display="flex"
            justifyContent="center"
            mt={isMedium ? 2 : 0}
            flexWrap={isMedium ? 'wrap' : ''}
          >
            {QRCodeFileFormats.map((item, index) => (
              <Button
                onClick={() => setQrFileFormat(item)}
                key={index}
                style={{
                  width: 'auto',
                  marginLeft: index === 0 ? 0 : 5,
                  backgroundColor: qrFileFormat === item ? '#35C0CA' : '#E0E0E0',
                  color: qrFileFormat === item ? '#FFF' : '#6A6A6A',
                  marginTop: index === 2 && isMedium ? 5 : 15,
                }}
                size="small"
              >
                {item}
              </Button>
            ))}
          </Box> */}
          <Button
            variant='outlined'
            id='download-qr'
            fullWidth
            size='large'
            aria-controls={openQrList ? 'qr-list' : undefined}
            aria-haspopup='true'
            aria-expanded={openQrList ? 'true' : undefined}
            onClick={handleDownloadQRClick}
            style={{
              marginTop: 10,
              fontSize: isMedium ? 11 : '',
              border: '2px solid #35C0CA',
              color: '#35C0CA',
              fontWeight: 400,
              borderRadius: 20,
            }}
          >
            <Box display='flex'>
              <GetAppIcon style={{ marginTop: 1, marginRight: 5 }} />
              <Typography fontSize={10} style={{ textTransform: 'capitalize' }}>
                Download
              </Typography>
            </Box>
          </Button>
          <Menu
            id='qr-list'
            anchorEl={qrFormatList}
            open={openQrList}
            onClose={() => setQrFormatList(null)}
            MenuListProps={{
              'aria-labelledby': 'download-qr',
            }}
          >
            {QRCodeFileFormats.map((data, index) => (
              <MenuItem
                style={{
                  color: '#35C0CA',
                  '&:hover': {
                    color: '#35C0CA',
                  },
                }}
                value={data}
                key={index}
                onClick={() => {
                  setQrFileFormat(data);
                  setQrFormatList(null);
                  setQrDownloadLoading(true);
                }}
              >
                {data.toUpperCase()}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Paper>
    );
  };

  const _renderPayments = () => {
    return (
      <Paper className={classes.optionContainer}>
        <Typography className={classes.title} variant={'h1'}>
          Payment Status
        </Typography>
        <div className={classes.paymentStatusChip}>
          <SuperStatusAgency type={subscriptionPaymentStatus} fullWidth />
          <SuperStatusAgency type={invoicePaymentStatus} fullWidth />
          {/*           {paymentStatus.map((item, key) => {
            return <SuperStatusAgency type={paymentStatus} fullWidth />
          })} */}
        </div>
      </Paper>
    );
  };

  const _renderChangeRequest = () => {
    return (
      <>
        {changeRequestsData && (
          <Box borderRadius='50%' className={classes.CRBox}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              {changeRequestsData?.type}
            </Typography>

            <div className={classes.CRDescriptions}>
              <div className={classes.CRDescriptionLine}>
                <Typography className={classes.crBold} sx={{ mt: 2 }}>
                  {moment(changeRequestsData?.createdAt).format(
                    'MM/DD/YYYY h:ss A'
                  )}
                </Typography>
                {changeRequestsData?.type === 'Change Subscription' && (
                  <Box paddingY='10px'>
                    <Typography style={{ padding: '0px' }}>
                      Change subscription to{' '}
                      <strong>
                        {changeRequestsData.additionalData.packageLabel}
                      </strong>
                    </Typography>
                    <Typography style={{ padding: '0px' }}>
                      Change subsciption effective date to{' '}
                      <strong>
                        {moment(
                          changeRequestsData.additionalData.effectiveDate
                        ).format('DD/MM/YYYY')}
                      </strong>
                    </Typography>
                  </Box>
                )}
                <Typography sx={{ mt: 2 }}>
                  {changeRequestsData?.description}
                </Typography>
              </div>
              <Button
                className={classes.CRDoneButton}
                fullWidth
                variant='contained'
                // type="submit"
                disableElevation
                onClick={() => {
                  handleConfirmDialogOpen({
                    open: true,
                    type: 'change_request',
                    title: 'Complete Change Request',
                    message:
                      'Are you sure you want to complete the Change Request?',
                    data: changeRequestsData,
                  });
                }}
              >
                Done
              </Button>
            </div>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <Grid
        className={classes.root}
        //   style={{ width: isMdView ? "85vw" : "90vw" }}
        container
        spacing={2}
      >
        {qrDownloadLoading && (
          <DownloadQRCodeUI
            qrCodeRef={qrCodeRef}
            referralCodeLink={referralCodeLink}
            imageURL={PropertyMateLogo}
          />
        )}
        <Snackybar
          open={showMessage.open}
          setOpen={handleMessage}
          type={showMessage.type}
          message={showMessage.message}
        />
        <Grid item xs={12} md={12} lg={8}>
          <Typography className={classes.title} variant={'h1'}>
            Agency Admin Information
          </Typography>
          {_renderInfo()}
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Typography className={classes.title} variant={'h1'}>
            Agency Contacts
          </Typography>
          {_renderContacts()}
        </Grid>
      </Grid>
      {_renderChangeRequest()}
    </>
  );
};

export default withStyles(styles)(AgencyDetailsUpgradePaidForm);
