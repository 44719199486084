import axios from 'axios';
import { createUrl, createUrl2 } from './utils';
import { apiEndpoints } from './endpoints';

const API_KEY = process.env.REACT_APP_PUBLIC_API_KEY;

const default_client = () => {
  let api = axios;

  api.defaults.headers.common['Accept'] = 'application/json';
  /* 
  ?Use to modify Cache Control
   api.defaults.headers.common['Cache-Control'] = 'no-cache';
  */
  api.defaults.headers.common['Content-Type'] = 'application/json';
  api.defaults.headers.common['x-api-key'] = API_KEY;
  api.defaults.timeout = 1800000;

  api.interceptors.response.use(
    function (response) {
      if (response && response.data && response.data.message) {
        if (response.data.message === 'login successful') {
          if (response.data.data.token) {
            SET_API_TOKEN(response.data.data.token);
          }
        } else {
          CHECK_IF_TOKEN_EXIST();
        }
      }
      return response;
    },
    function (error) {
      console.error('[ API ERROR -> ]', error.response);

      if (error.response === undefined) {
        window.location.replace(window.location.href);
        return;
      }

      if (error.response && error.response.data && error.response.data.reason) {
        /**
         * JWT Expired Handler */
        let localRefreshToken = GET_REFRESH_TOKEN();

        /**
         * Failed to Refresh Token (Auto-Clear Session) */
        if (
          error.response.data.message ===
            'refresh token request unsuccessful' &&
          error.response.status === 400
        ) {
          console.debug('started clearing session...');
          localStorage.clear();
          localStorage.setItem('showSnackBar', true);
          window.location.replace(window.location.origin);
          return;
        }

        /**
         * Refresh Token */
        if (error.response.status === 400) {
          if (
            error.response.data.reason.message === 'jwt malformed' ||
            error.response.data.reason.message === 'jwt expired'
          ) {
            console.debug('--- refreshing token ---');
            let params = {
              refreshToken: localRefreshToken,
            };
            POST(apiEndpoints.REFRESH_TOKEN, params)
              .then((response) => {
                if (response.error) {
                  throw response.error;
                }
                if (localStorage.getItem('persist:root') !== null) {
                  let localReduxState = JSON.parse(
                    localStorage.getItem('persist:root')
                  );

                  if (localReduxState) {
                    let currentURL = window.location.href;
                    if (!currentURL.includes('/superadmin')) {
                      /**
                       * * Only fire when outside superadmin container
                       */
                      let localReduxStateAuth = JSON.parse(
                        localReduxState.authentication
                      );
                      if (response.data && response.data.data) {
                        localReduxStateAuth.token = response.data.data;
                        let updatedReduxStateAuth = {
                          ...localReduxState,
                          authentication: JSON.stringify({
                            ...localReduxStateAuth,
                          }),
                        };
                        localStorage.setItem(
                          'persist:root',
                          JSON.stringify(updatedReduxStateAuth)
                        );
                        window.location.replace(window.location.href);
                        SET_API_TOKEN(response.data.data.token);
                      }
                      return response;
                    }
                    if (currentURL.includes('/superadmin')) {
                      /**
                       * * Fire when inside superadmin container
                       */
                      let localReduxStateSuperadmin = JSON.parse(
                        localReduxState.superadmin
                      );
                      if (response.data && response.data.data) {
                        localReduxStateSuperadmin.superadmintoken =
                          response.data.data;
                        let updatedReduxStateSuper = {
                          ...localReduxState,
                          superadmin: JSON.stringify({
                            ...localReduxStateSuperadmin,
                          }),
                        };
                        localStorage.setItem(
                          'persist:root',
                          JSON.stringify(updatedReduxStateSuper)
                        );
                        window.location.replace(window.location.href);
                        SET_API_TOKEN(response.data.data.token);
                      }
                      return response;
                    }
                  }
                }
              })
              .catch((error) => {
                console.error('error on refresh token... clearing session...');
              });
          }
        }
      }

      return Promise.reject(error);
    }
  );

  return { ...api };
};

const API = default_client();

function FETCH(url, params = {}) {
  return API.get(createUrl(url), params);
}

function FETCH_V2(url, params = {}) {
  return API.get(createUrl2(url), params);
}

function POST(url, params = {}) {
  return API.post(createUrl(url), params);
}

function POST_V2(url, params = {}) {
  return API.post(createUrl2(url), params);
}

function PUT(url, params = {}) {
  return API.put(createUrl(url), params);
}

function PUT_V2(url, params = {}) {
  return API.put(createUrl2(url), params);
}

function DELETE(url, params = {}) {
  return API.delete(createUrl(url), params);
}

const SET_API_TOKEN = (token) => {
  API.defaults.headers.common['x-jwt'] = `Bearer ${token}`;
  API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  API.token = token;
};

const REMOVE_API_TOKEN = () => {
  API.defaults.headers.common['x-jwt'] = null;
  API.defaults.headers.common['Authorization'] = null;
  API.token = null;
};

const CHECK_IF_TOKEN_EXIST = (token) => {
  /**
   * * Token Resetter
   * ? IMPORTANT KEY PART OF AUTHENTICATION
   */
  console.debug('--- Check Token Exist ---');
  if (localStorage.getItem('persist:root') !== null) {
    let localReduxState = JSON.parse(localStorage.getItem('persist:root'));
    if (localReduxState) {
      let currentURL = window.location.href;
      if (!currentURL.includes('/superadmin')) {
        /**
         * * Only fire when outside superadmin container
         */
        let reduxAuth = JSON.parse(localReduxState.authentication);
        if (reduxAuth?.token?.token) {
          SET_API_TOKEN(reduxAuth?.token?.token);
        }
      }
      if (currentURL.includes('/superadmin')) {
        /**
         * * Fire when inside superadmin container
         */
        let reduxSuperadmin = JSON.parse(localReduxState.superadmin);
        SET_API_TOKEN(reduxSuperadmin?.superadmintoken?.token);
      }
    }
  }
};

function GET_REFRESH_TOKEN() {
  /**
   * * Token Refresher
   * ? IMPORTANT KEY PART OF AUTHENTICATION
   */
  console.debug('--- Get Refresh Token ---');
  if (localStorage.getItem('persist:root') !== null) {
    let localReduxState = JSON.parse(localStorage.getItem('persist:root'));
    if (localReduxState) {
      let currentURL = window.location.href;
      if (!currentURL.includes('/superadmin')) {
        /**
         * * Only fire when outside superadmin container
         */
        let reduxAuth = JSON.parse(localReduxState.authentication);
        return reduxAuth?.token?.refreshToken;
      }
      if (currentURL.includes('/superadmin')) {
        /**
         * * Fire when inside superadmin container
         */
        let reduxSuperadmin = JSON.parse(localReduxState.superadmin);
        return reduxSuperadmin?.superadmintoken?.refreshToken;
      }
    }
  }
}

CHECK_IF_TOKEN_EXIST();

export {
  FETCH,
  FETCH_V2,
  POST,
  POST_V2,
  PUT,
  PUT_V2,
  DELETE,
  SET_API_TOKEN,
  REMOVE_API_TOKEN,
  CHECK_IF_TOKEN_EXIST,
};

export default API;
