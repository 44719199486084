import PrivacyPolicyMobileApp from '../../containers/Web/PrivacyPolicyMobileApp'
import TermsMobileApp from '../../containers/Web/TermsMobileApp'

let routes = [
  {
    path: '/privacy-policy-mobile-app',
    component: PrivacyPolicyMobileApp,
  },
  {
    path: '/terms-and-conditions-mobile-app',
    component: TermsMobileApp,
  },
]

export default routes
