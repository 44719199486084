import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  getPricePackages,
  getPricePackagesV2,
} from 'store/selectors/billing.selectors';

import { billingActions, agencyv2Actions } from 'store/actions';
import {
  requestGETAPIBilling,
  requestGETAPIBillingV2,
  requestPOSTAPIAgency,
} from 'store/api';
import AlertNotification from 'components/Web/Form/AlertNotification';
import DashboardBanner from 'components/Web/Dashboard/DashboardBanner';
import {
  getSuccessPayload,
  getStatus,
  getErrors,
} from 'store/selectors/agencyv2.selectors';
import dashBannerIMG from 'assets/images/agentbanner.png';
import Crumbs from 'components/Web/Header/Crumbs';
import SelectField from 'components/Web/Form/SelectField';
import TextAreaField from 'components/Web/Form/TextAreaField';
import { getUser } from 'store/selectors/authentication.selectors';
import CustomDatePicker from 'components/Web/Filter/CustomDatePicker';

const styles = (theme) => ({
  root: {
    backgroundColor: '#F5F5F5',
    paddingLeft: 90,
    paddingRight: theme.spacing(2),
    margin: '0 auto',
    width: '100%',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
  formWrapper: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
    '@media (max-width: 960px) ': {
      margin: '0 auto',
      '& > h2': {
        textAlign: 'center',
      },
    },
  },
  btn: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: '9px 15px',
    minWidth: '118px',
    color: '#ffffff',
    '& .MuiButton-label > span': {
      display: 'inline-flex',
    },

    '& .MuiButton-label > span svg': {
      color: '#ffffff !important',
    },
  },
  btnsWrap: {
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: theme.spacing(2),
  },
});

const ChangeRequest = (props) => {
  const { classes } = props;

  const dispatch = useDispatch();
  const AGENCY = useSelector(getUser);
  const PACKAGES = useSelector(getPricePackages);
  const PACKAGESV2 = useSelector(getPricePackagesV2);
  const CHANGE_REQUEST = useSelector(getSuccessPayload);
  const CHANGE_REQUEST_STATUS = useSelector(getStatus);
  const CHANGE_REQUEST_ERROR = useSelector(getErrors);

  const [isLoading, setIsLoading] = useState(false);
  const defaultNotification = { status: false, options: null };
  const [isNotification, setIsNotification] = useState(defaultNotification);
  const today = new Date();
  const [effectiveDate, setEffectiveDate] = useState('');
  const handleNotificationClose = () => {
    setIsNotification(defaultNotification);
  };

  const [packages, setPackages] = useState(null);
  const [packagesv2, setPackagesV2] = useState(null);
  //form fields
  const [typeOfChange, setTypeOfChange] = useState('');
  const [changeSubscriptionTo, setChangeSubscriptionTo] = useState('');

  const [details, setDetails] = useState('');
  const defaultErrors = {
    typeOfChange: {
      error: false,
      message: 'Type of Change is required.',
    },
    details: {
      error: false,
      message: 'Details is required.',
    },
    changeSubscriptionTo: {
      error: false,
      message: 'Please choose a subscription.',
    },
  };
  const [errors, setErrors] = useState(defaultErrors);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const typeOfChangeOptions = [
    'Change Subscription',
    'Update Account Details',
    'Others',
  ];

  window.onbeforeunload = (e) => {
    return handleResetState();
  };

  useEffect(() => {
    handleResetState();
    handleStatusClear();
    apiRequestGetPricePackages();
    apiRequestGetPricePackagesV2();
    return () => {
      handleResetState();
      handleStatusClear();
      document.getElementById('root').style.backgroundColor = 'initial';
    };
  }, []);

  const apiRequestGetPricePackages = () => {
    setIsLoading(true);
    const request = {
      query: null,
      data: null,
    };
    handleGetPackageClear();
    dispatch(requestGETAPIBilling(request, 'STRIPE_GET_PRICES', 'REQUEST'));
  };

  const apiRequestGetPricePackagesV2 = () => {
    setIsLoading(true);
    const request = {
      query: null,
      data: null,
    };
    handleGetPackageClearV2();
    dispatch(requestGETAPIBillingV2(request, 'PACKAGES_GET_PRICES', 'REQUEST'));
  };

  const handleGetPackageClear = () => {
    dispatch(billingActions(null, 'STRIPE_GET_PRICES', 'CLEAR'));
  };

  const handleGetPackageClearV2 = () => {
    dispatch(billingActions(null, 'PACKAGES_GET_PRICES', 'CLEAR'));
  };

  const handleStatusClear = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_STATUS'));
  };

  const handleResetState = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'RESET_STATES'));
  };

  const apiPostChangeRequest = () => {
    setIsLoading(true);

    // Get package label
    const packageLabel = changeSubscriptionTo
      ? packagesv2?.find(
          (subsriptionPackage) => subsriptionPackage.id === changeSubscriptionTo
        ).label
      : null;

    const request = {
      query: null,
      data: {
        agency: {
          companyName: AGENCY?.agency?.companyName,
          agencyName: AGENCY?.agency?.officeName,
        },
        type: typeOfChange,
        description: details,
        additionalData: {
          packageLabel: packageLabel,
          priceId: changeSubscriptionTo,
          effectiveDate: effectiveDate ?? today,
        },
        agencyReference: AGENCY?.agency?._id,
      },
    };
    handleStatusClear();
    dispatch(
      requestPOSTAPIAgency(request, 'AGENCY_POST_CHANGE_REQUEST', 'REQUEST')
    );
  };

  const handleRequestSubmit = () => {
    apiPostChangeRequest();
  };

  const stripePackageHandler = (stripePackages) => {
    const selectPackagesField = [];

    let singlePackage = {};

    if (stripePackages) {
      stripePackages.forEach((stripepackage) => {
        if (stripepackage.subscriptionType !== 'EXTENDED FREE') {
          singlePackage = {
            label: `${stripepackage?.name}`,
            id: stripepackage?.pkgId,
            value: stripepackage?.pkgId,
          };
          selectPackagesField.push(singlePackage);
        }
      });
    }

    return selectPackagesField;
  };

  const handleSimpleValidation = () => {
    switch (true) {
      case typeOfChange === '' && details !== '':
        setIsButtonDisabled(true);
        setErrors({
          ...errors,
          typeOfChange: { ...errors.typeOfChange, error: true },
        });
        break;

      case (details === '' && typeOfChange === typeOfChangeOptions[1]) ||
        (details === '' && typeOfChange === typeOfChangeOptions[2]):
        setIsButtonDisabled(true);
        setErrors({ ...errors, details: { ...errors.details, error: true } });
        break;
      case typeOfChange === typeOfChangeOptions[0] &&
        (!changeSubscriptionTo || !effectiveDate):
        setIsButtonDisabled(true);
        setErrors(defaultErrors);
        break;

      case typeOfChange === '' && details === '':
        setIsButtonDisabled(true);
        setErrors(defaultErrors);
        break;
      default:
        setIsButtonDisabled(false);
        setErrors(defaultErrors);
    }
  };

  const handleEffectiveDate = (data) => {
    setEffectiveDate(data);
  };

  useEffect(() => {
    handleSimpleValidation();
  }, [typeOfChange, changeSubscriptionTo, details, effectiveDate]);

  useEffect(() => {
    if (PACKAGES?.packages) {
      setPackages(stripePackageHandler(PACKAGES?.packages));
    }

    if (PACKAGES) {
      if (PACKAGES.status === 'billing/STRIPE_GET_PRICES_SUCCESS') {
        setIsLoading(false);
      } else if (PACKAGES.status === 'billing/STRIPE_GET_PRICES_FAILED') {
        setIsLoading(false);
        setIsNotification({
          status: true,
          options: {
            severity: 'error',
            message: 'Error getting list of subscription packages.',
          },
        });
      }
    }
  }, [PACKAGES]);

  useEffect(() => {
    if (PACKAGESV2?.packages) {
      setPackagesV2(stripePackageHandler(PACKAGESV2?.packages));
    }

    if (PACKAGESV2) {
      if (PACKAGESV2.status === 'billing/PACKAGES_GET_PRICES_SUCCESS') {
        setIsLoading(false);
      } else if (PACKAGESV2.status === 'billing/PACKAGES_GET_PRICES_FAILED') {
        setIsLoading(false);
        setIsNotification({
          status: true,
          options: {
            severity: 'error',
            message: 'Error getting list of subscription packages.',
          },
        });
      }
    }
  }, [PACKAGESV2]);

  useEffect(() => {
    if (CHANGE_REQUEST || CHANGE_REQUEST_STATUS) {
      if (
        CHANGE_REQUEST_STATUS === 'agency/AGENCY_POST_CHANGE_REQUEST_SUCCESS' &&
        CHANGE_REQUEST
      ) {
        setIsLoading(false);
        setIsNotification({
          status: true,
          options: {
            severity: 'success',
            message: 'Change request successfully sent.',
          },
        });
      } else if (
        CHANGE_REQUEST_STATUS === 'agency/AGENCY_POST_CHANGE_REQUEST_FAILED'
      ) {
        setIsLoading(false);
        setIsNotification({
          status: true,
          options: {
            severity: 'error',
            message: 'Error submitting your request.',
          },
        });
      }
    }
  }, [CHANGE_REQUEST, CHANGE_REQUEST_STATUS, CHANGE_REQUEST_ERROR]);

  const CRUMBS = [
    { label: 'Agency Dashboard', route: '/agency/dashboard' },
    { label: 'Change Request', route: '/agency/change-request' },
  ];
  return (
    <div className={classes.root}>
      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}

      <DashboardBanner
        title={'Change Request'}
        content={
          <Typography
            variant='body1'
            component='div'
            dangerouslySetInnerHTML={{
              __html:
                AGENCY?.agency?.bannerIntro && AGENCY?.agency.bannerIntro !== ''
                  ? AGENCY?.agency.bannerIntro
                  : 'Welcome!',
            }}
          ></Typography>
        }
        image={dashBannerIMG}
      />
      <Container style={{ maxWidth: 1012, padding: '24px 0' }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <Crumbs type={'DARK'} crumbsOption={CRUMBS} />
          </Grid>
        </Grid>
        <Paper className={classes.formWrapper}>
          <Typography variant={'body1'} component={'p'}>
            Please fill out the form below according to your change of request.
          </Typography>
          <SelectField
            options={typeOfChangeOptions}
            placeholder={'Type of Change*'}
            showError={errors.typeOfChange.error}
            errorMsg={errors.typeOfChange.message}
            value={typeOfChange}
            selected={typeOfChange}
            onChange={(e, value) => {
              setTypeOfChange(value);
            }}
          />
          {typeOfChange === 'Change Subscription' && packages && (
            <>
              <SelectField
                options={packages}
                placeholder={'Choose new subscription*'}
                errorMsg={errors.changeSubscriptionTo.message}
                showError={errors.changeSubscriptionTo.error}
                value={changeSubscriptionTo}
                selected={changeSubscriptionTo}
                onChange={(e, value) => {
                  setChangeSubscriptionTo(value);
                }}
              />
              <CustomDatePicker
                isForm={true}
                value={effectiveDate}
                handleDate={handleEffectiveDate}
                isClearFilter={false}
                minDate={today}
                placeholder={'Effective Date'}
              />
            </>
          )}
          <TextAreaField
            aria-label='Describe details of your change request'
            placeholder='Describe details of your change request'
            showError={errors.details.error}
            errorMsg={errors.details.message}
            value={details}
            onChange={(e) => {
              setDetails(e.target.value);
            }}
          />
          <div className={classes.btnsWrap}>
            <Button
              className={classes.btn}
              variant={'contained'}
              color={'primary'}
              onClick={() => handleRequestSubmit()}
              disabled={isButtonDisabled}
            >
              Send Request
            </Button>
          </div>
        </Paper>
      </Container>
    </div>
  );
};

export default withStyles(styles)(ChangeRequest);
