import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SuperBreadCrumbs,
  AgencyDetailsForm,
  Snackybar,
} from 'components/Web/Superadmin';

import ConfirmDialog from 'components/Web/CustomDialogs/ConfirmDialog';
import AlertNotification from 'components/Web/Form/AlertNotification';
import { Loader } from 'components/Common';
import {
  getPricePackages,
  getPricePackagesV2,
} from 'store/selectors/billing.selectors';
import { billingActions } from 'store/actions/billing.actions';
import {
  requestGETAPIBilling,
  requestGETAPIBillingV2,
} from 'store/api/billing.api';
import OFFICES from '../../../assets/data/offices.json';
/** actions */
import { superadminActions, agencyv2Actions } from 'store/actions';

import {
  requestPOSTAPISuperadmin,
  requestGETAPISuperadmin,
  requestPUTAPIAgency,
  requestPUTAPISuperadminAgency,
} from 'store/api';
/** selectors */
import {
  getStatus,
  getErrors,
  getAgencies,
  getAgency,
  getSuperadminChangeRequests,
  getOffice,
  getOfficeLists,
} from 'store/selectors/superadmin.selectors';

import {
  getStatus as getAgencyStatus,
  getAgencyUpload,
} from 'store/selectors/agencyv2.selectors';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
  },
});

const SuperadminChangeRequestsDetails = (props) => {
  const { classes, history, match } = props;

  const dispatch = useDispatch();

  const STATUS = useSelector(getStatus);
  const ERROR = useSelector(getErrors);
  const AGENCIES = useSelector(getAgencies);
  const AGENCY = useSelector(getAgency);
  const AGENCY_STATUS = useSelector(getAgencyStatus);
  const PACKAGES = useSelector(getPricePackages);
  const PACKAGESV2 = useSelector(getPricePackagesV2);
  const CHANGE_REQUESTS = useSelector(getSuperadminChangeRequests);
  const AGENCY_UPLOAD = useSelector(getAgencyUpload);
  //const OFFICES_LIST = OFFICES
  const OFFICES_LIST = useSelector(getOfficeLists);
  const OFFICE_DETAILS = useSelector(getOffice);

  //packages
  const [packages, setPackages] = useState(null);
  const [packagesv2, setPackagesV2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //office lists
  const [officeLists, setOfficeLists] = useState(null);

  const handleGetPackageClear = () => {
    dispatch(billingActions(null, 'STRIPE_GET_PRICES', 'CLEAR'));
  };

  const handleGetPackageClearV2 = () => {
    dispatch(billingActions(null, 'PACKAGES_GET_PRICES', 'CLEAR'));
  };

  const handleGetOfficeListsClear = () => {
    dispatch(superadminActions(null, 'SUPERADMIN_GET_OFFICE_LISTS', 'CLEAR'));
  };

  const handleUploadImage = (data, type) => {
    const params = {
      query: AGENCY?.data?.entity?._id + `/?photo=${type}`,
      data: data,
    };
    dispatch(requestPUTAPIAgency(params, 'AGENCY_UPLOAD_IMAGE'));
  };
  const apiRequestGetPricePackages = () => {
    setIsLoading(true);
    const request = {
      query: null,
      data: null,
    };
    handleGetPackageClear();
    dispatch(requestGETAPIBilling(request, 'STRIPE_GET_PRICES', 'REQUEST'));
  };

  const apiRequestGetPricePackagesV2 = () => {
    setIsLoading(true);
    const request = {
      query: null,
      data: null,
    };
    handleGetPackageClearV2();
    dispatch(requestGETAPIBillingV2(request, 'PACKAGES_GET_PRICES', 'REQUEST'));
  };

  const apiRequestGetOfficeLists = () => {
    setIsLoading(true);
    const request = {
      query: '',
      data: null,
    };
    handleGetOfficeListsClear();
    dispatch(
      requestGETAPISuperadmin(request, 'SUPERADMIN_GET_OFFICE_LISTS', 'REQUEST')
    );
  };

  // end of packages
  const defaultNotification = { status: false, options: null };

  const [docusignRequest, setDocusignRequest] = useState(false);
  const [docusignResendRequest, setResendDocusignRequest] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    type: '', // * activate_account | pause_account | delete_account | send_docusign | save_draft
    title: '',
    message: '',
    data: null,
    continue: false,
  });
  const [currentRequest, setCurrentRequest] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState({
    open: false,
    type: 'info',
    message: '',
  });
  const [initialData, setInitialData] = useState(null);
  const [initialDataCR, setInitialDataCR] = useState(null);
  const [password, setPassword] = useState(null);
  /** Alert Notification */
  const [notificationOptions, setNotificationOptions] = useState(null);
  const [isNotification, setIsNotification] = useState(false);
  const [recentStatus, setRecentStatus] = useState(false);

  const [office, setOffice] = useState(initialData?.reaAgencyId || '');
  const [officeId, setOfficeId] = useState(initialData?.reaAgencyId || '');

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'secondary',
      label: 'Manage Change Requests',
      path: '/superadmin/change-requests',
    },
    {
      type: 'primary',
      label: 'Manage Change Request Information',
      path: '',
    },
  ];

  //status
  const allStatusRequest = [
    'superadmin/SUPERADMIN_GET_AGENCY_DATA_REQUEST',
    'superadmin/SUPERADMIN_GET_CHANGE_REQUESTS_DATA_REQUEST',
    'superadmin/SUPERADMIN_UPDATE_ACCOUNT_STATUS_REQUEST',
    'superadmin/SUPERADMIN_PAUSE_ACCOUNT_REQUEST',
    'superadmin/SUPERADMIN_DELETE_ACCOUNT_REQUEST',
    'superadmin/SUPERADMIN_UPDATE_CHANGE_REQUESTS_REQUEST',
    'superadmin/SUPERADMIN_SAVE_DRAFT_AGENCY_REQUEST',
    'superadmin/SUPERADMIN_SEND_DOCUSIGN_AGENCY_REQUEST',
    'superadmin/SUPERADMIN_GET_OFFICE_DATA_REQUEST',
    'superadmin/SUPERADMIN_SEND_OFFICE_REFERENCE_REQUEST',
  ];

  const allStatusSuccess = [
    'superadmin/SUPERADMIN_UPDATE_ACCOUNT_STATUS_SUCCESS',
    'superadmin/SUPERADMIN_PAUSE_ACCOUNT_SUCCESS',
    'superadmin/SUPERADMIN_DELETE_ACCOUNT_SUCCESS',
    'superadmin/SUPERADMIN_UPDATE_CHANGE_REQUESTS_SUCCESS',
    'superadmin/SUPERADMIN_SAVE_DRAFT_AGENCY_SUCCESS',
    'superadmin/SUPERADMIN_GET_OFFICE_DATA_SUCCESS',
    'superadmin/SUPERADMIN_SEND_OFFICE_REFERENCE_SUCCESS',
  ];

  const allStatusFailed = [
    'superadmin/SUPERADMIN_GET_AGENCY_DATA_FAILED',
    'superadmin/SUPERADMIN_GET_CHANGE_REQUESTS_DATA_FAILED',
    'superadmin/SUPERADMIN_UPDATE_ACCOUNT_STATUS_FAILED',
    'superadmin/SUPERADMIN_PAUSE_ACCOUNT_FAILED',
    'superadmin/SUPERADMIN_DELETE_ACCOUNT_FAILED',
    'superadmin/SUPERADMIN_UPDATE_CHANGE_REQUESTS_FAILED',
    'superadmin/SUPERADMIN_SAVE_DRAFT_AGENCY_FAILED',
    'superadmin/SUPERADMIN_SEND_DOCUSIGN_AGENCY_FAILED',
    'superadmin/SUPERADMIN_GET_OFFICE_DATA_FAILED',
    'superadmin/SUPERADMIN_SEND_OFFICE_REFERENCE_FAILED',
  ];

  const officesHandler = (officeList) => {
    const selectOfficesField = [];

    const defaultOption = { label: 'Other', value: 'Other', _id: 'Other' };
    let singleOffice = {};

    selectOfficesField.push(defaultOption);
    if (officeList) {
      officeList.forEach((offices) => {
        singleOffice = {
          label: `${
            offices.registeredTradeName
              ? offices.registeredTradeName
              : offices.companyName
          } - ${offices.reaAgencyId}`,
          value: offices.reaAgencyId,
          _id: offices._id,
        };
        selectOfficesField.push(singleOffice);
      });
    }

    // sort by name
    let byLabel = selectOfficesField.slice(0);
    byLabel.sort(function (a, b) {
      let x = a.label.toLowerCase();
      let y = b.label.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });

    return byLabel;
  };

  //packages
  useEffect(() => {
    if (!packages) {
      apiRequestGetPricePackages();
    }
    if (!packagesv2) {
      apiRequestGetPricePackagesV2();
    }
    if (!officeLists) {
      apiRequestGetOfficeLists();
    }
  }, []);

  useEffect(() => {
    if (PACKAGES?.packages) {
      setPackages(PACKAGES?.packages);
    }

    if (PACKAGES) {
      if (
        PACKAGES.status === 'billing/STRIPE_GET_PRICES_SUCCESS' ||
        PACKAGES.status === 'billing/STRIPE_GET_PRICES_FAILED'
      ) {
        setIsLoading(false);
      }
    }
  }, [PACKAGES]);

  useEffect(() => {
    if (PACKAGESV2?.packages) {
      setPackagesV2(PACKAGESV2?.packages);
    }

    if (PACKAGESV2) {
      if (
        PACKAGESV2.status === 'billing/PACKAGES_GET_PRICES_SUCCESS' ||
        PACKAGESV2.status === 'billing/PACKAGES_GET_PRICES_FAILED'
      ) {
        setIsLoading(false);
      }
    }
  }, [PACKAGESV2]);

  useEffect(() => {
    if (OFFICES_LIST?.officeLists) {
      setOfficeLists(OFFICES_LIST?.officeLists);
    }

    if (OFFICES_LIST) {
      if (
        OFFICES_LIST.status ===
          'superadmin/SUPERADMIN_GET_OFFICE_LISTS_SUCCESS' ||
        OFFICES_LIST.status === 'superadmin/SUPERADMIN_GET_OFFICE_LISTS_FAILED'
      ) {
        setIsLoading(false);
      }
    }
  }, [OFFICES_LIST]);

  useEffect(() => {
    if (OFFICE_DETAILS?.agency) {
      setConfirmDialog({
        open: true,
        type: 'send_officeId',
        title: `${
          OFFICE_DETAILS?.agency?.registeredTradeName
            ? OFFICE_DETAILS?.agency?.registeredTradeName
            : OFFICE_DETAILS?.agency?.companyName
        } - ${OFFICE_DETAILS?.agency?.reaAgencyId} `,
        message: `
        ${OFFICE_DETAILS?.propertySummary?.['On-Sale']}  On-Sale Properties,\n
        ${OFFICE_DETAILS?.propertySummary?.sold} Sold Properties,\n
        ${OFFICE_DETAILS?.propertySummary?.rental} Rental Properties,\n


        Do you want to claim this agency for this account?
        `,
        data: {
          oldAgencyReference: OFFICE_DETAILS?.agency?._id,
          newAgencyReference: match.params.id,
        },
      });
    }
    handleClearOfficeDetails();
  }, [OFFICE_DETAILS]);

  useEffect(() => {
    handleClearAgencyData();
    handleClearChangeRequestData();
    handleGetAgencyData();
    handleClearOfficeDetails();
    handleGetChangeRequestData();
    /**
     * * Set Default Values
     */
    handleClearError();
    handleClearStatus();
    handleClearUploadsStatuses();
    return () => {
      handleClearAgencyData();
      handleClearOfficeDetails();
      handleClearChangeRequestData();
      handleClearUploadsStatuses();
      handleClearError();
      handleClearStatus();
    };
  }, []);

  window.onbeforeunload = (e) => {
    return () => {
      handleClearAgencyData();
      handleClearOfficeDetails();
      handleClearChangeRequestData();
      handleClearUploadsStatuses();
      handleClearError();
      handleClearStatus();
    };
  };

  useEffect(() => {
    if (AGENCY?.data && AGENCY?.data?.entity) {
      setInitialData(AGENCY?.data?.entity);
    }
  }, [AGENCY]);

  useEffect(() => {
    if (CHANGE_REQUESTS?.data && CHANGE_REQUESTS?.data?.entities) {
      setInitialDataCR(CHANGE_REQUESTS?.data?.entities);
    }
  }, [CHANGE_REQUESTS]);

  useEffect(() => {
    if (AGENCY_STATUS === 'agency/UPDATE_AGENCY_DETAILS_SUCCESS') {
      setLoading(false);
      handleClearAgencyData();
      handleGetAgencyData();
      /**
       * * Set Default Values
       */
      handleClearError();
      handleClearStatus();
    }
    if (
      AGENCY_STATUS === 'agency/UPDATE_AGENCY_DETAILS_SUCCESS' &&
      docusignRequest == true
    ) {
      handleSendAgencyDocusign();
    }
    if (
      AGENCY_STATUS === 'agency/UPDATE_AGENCY_DETAILS_SUCCESS' &&
      docusignResendRequest == true
    ) {
      handleReSendAgencyDocusign();
    }
    if (AGENCY_STATUS === null) {
      setIsNotification(false);
    }
    if (AGENCY_STATUS?.includes('REQUEST')) {
      setIsNotification(true);
      setLoading(true);
      setNotificationOptions({
        message: 'Updating agency details',
        severity: 'info',
      });
    }
    if (AGENCY_STATUS?.includes('SUCCESS') && recentStatus) {
      setIsNotification(true);
      setLoading(false);
      setNotificationOptions({
        message: 'Agency details updated',
        severity: 'success',
      });
    }
    if (AGENCY_STATUS?.includes('FAILED') && recentStatus) {
      setIsNotification(true);
      setLoading(false);
      setNotificationOptions({
        message: 'Agency details update failed',
        severity: 'error',
      });
    }
    setTimeout(() => setIsNotification(false), 2000);
    return () => {
      setRecentStatus(true);
    };
  }, [AGENCY_STATUS]);

  useEffect(() => {
    if (allStatusRequest.some((i) => STATUS?.includes(i))) {
      setLoading(true);
      handleClearError();
    }
    if (allStatusFailed.some((i) => STATUS?.includes(i))) {
      setLoading(false);
      setShowMessage({
        open: true,
        type: 'error',
        message: ERROR?.message ? ERROR.message : ERROR,
      });
    }
    if (allStatusSuccess.some((i) => STATUS?.includes(i))) {
      setLoading(false);
      setShowMessage({
        open: true,
        type: 'success',
        message: 'Success',
      });
    }
    if (STATUS === 'superadmin/SUPERADMIN_UPDATE_CHANGE_REQUESTS_SUCCESS') {
      handleClearChangeRequestData();
      handleGetChangeRequestData();
    }

    /*     if (STATUS === 'superadmin/SUPERADMIN_SEND_OFFICE_REFERENCE_REQUEST') {
      setLoading(true)
      handleClearError()
      setNotificationOptions({
        message: 'Sending Agency Reference',
        severity: 'info',
      })
    }
    if (STATUS === 'superadmin/SUPERADMIN_SEND_OFFICE_REFERENCE_SUCCESS') {
      setLoading(false)
      setNotificationOptions({
        message: 'Agency Reference updated',
        severity: 'success',
      })
    }
    if (STATUS === 'superadmin/SUPERADMIN_SEND_OFFICE_REFERENCE_FAILED') {
      setIsNotification(true)
      setLoading(false)
      setNotificationOptions({
        message: 'Office update failed',
        severity: 'error',
      })
    } */
    if (STATUS === 'superadmin/SUPERADMIN_SEND_DOCUSIGN_AGENCY_REQUEST') {
      setLoading(true);
      handleClearError();
      setNotificationOptions({
        message: 'Sending docusign',
        severity: 'info',
      });
    }
    if (STATUS === 'superadmin/SUPERADMIN_SEND_DOCUSIGN_AGENCY_SUCCESS') {
      setLoading(false);
      setConfirmDialog({
        open: true,
        type: '',
        title: 'Docusign Status',
        message: 'The Docusign has been sent to the agency.',
        continue: true,
      });
      handleClearAgencyData();
      handleGetAgencyData();
      /**
       * * Set Default Values
       */
      handleClearError();
      handleClearStatus();
    }
    handleClearStatus();
  }, [STATUS, ERROR]);

  useEffect(() => {
    if (
      AGENCY_UPLOAD &&
      AGENCY_UPLOAD.status === 'agency/AGENCY_UPLOAD_IMAGE_SUCCESS'
    ) {
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Upload successful!',
          autoHideDuration: 3000,
          onClose: () => {
            setNotificationOptions(defaultNotification);
            setIsNotification(false);
            handleClearUploadsStatuses();
          },
        },
      };
      setNotificationOptions(options?.options);
      setIsNotification(true);
    } else if (
      AGENCY_UPLOAD &&
      AGENCY_UPLOAD.status === 'agency/AGENCY_UPLOAD_IMAGE_REQUEST'
    ) {
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: 'Uploading image...',
          autoHideDuration: 3000,
        },
      };
      setNotificationOptions(options?.options);
      setIsNotification(true);
    } else if (
      AGENCY_UPLOAD &&
      AGENCY_UPLOAD.status === 'agency/AGENCY_UPLOAD_IMAGE_FAILED'
    ) {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message:
            AGENCY_UPLOAD?.payload_failed?.data?.reason?.message ??
            'Error uploading image. Please try again.',
          autoHideDuration: 4000,
        },
      };
      setNotificationOptions(options?.options);
      setIsNotification(true);
    }
  }, [AGENCY_UPLOAD]);

  const handleClearUploadsStatuses = () => {
    dispatch(agencyv2Actions(null, 'AGENCY_UPLOAD_IMAGE', 'CLEAR_STATUS'));
    dispatch(agencyv2Actions(null, 'AGENCY_UPLOAD_IMAGE', 'CLEAR_ERROR'));
  };

  const handleMessage = (state) => {
    setShowMessage({
      ...showMessage,
      open: state,
    });
  };

  const handleConfirmDialogCallback = () => {
    handleConfirmDialogClose();
    if (confirmDialog.type === 'activate_account') {
      // * call activate API
      handleChangeAgencyAccountStatus();
    }
    if (confirmDialog.type === 'pause_account') {
      // * call pause API
      handlePauseAgencyAccount();
    }
    if (confirmDialog.type === 'delete_account') {
      // * call delete API
      handleDeleteAgencyAccount();
    }
    if (confirmDialog.type === 'send_docusign') {
      handleSaveAgencyDraft();
      setDocusignRequest(true);
    }
    if (confirmDialog.type === 'resend_docusign') {
      handleSaveAgencyDraft();
      setResendDocusignRequest(true);
    }
    if (confirmDialog.type === 'save_draft') {
      // * call save draft API
      handleSaveAgencyDraft();
    }
    if (confirmDialog.type === 'change_request') {
      // * call save draft API
      handleUpdateChangeRequest(CHANGE_REQUESTS?.data?.entities[0]);
    }
    if (confirmDialog.type === 'send_officeId') {
      // * call POST /agency/match/agencyReference API
      handlePostAgencyReference(confirmDialog.data);
    }
  };

  const handleConfirmDialogOpen = (data) => {
    if (data.type === 'activate_account' && data.password) {
      setPassword(data.password);
    }
    setConfirmDialog({
      open: data.open,
      type: data.type,
      title: data.title,
      message: data.message,
      data: data.data,
    });
    if (data && data.data) {
      setCurrentRequest(data.data);
    }
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialog({
      ...confirmDialog,
      open: false,
    });
  };

  function closeNotification() {
    setIsNotification(false);
  }

  /** API Request */
  const handleGetAgencyData = () => {
    let request = {
      query: '/' + match.params.id,
      data: {},
    };
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_AGENCY_DATA'));
  };

  const handleGetChangeRequestData = () => {
    let request = {
      query: '?changeIntentState=NONE' + '&agencyReference=' + match.params.id,
      data: {},
    };
    dispatch(
      requestGETAPISuperadmin(request, 'SUPERADMIN_GET_CHANGE_REQUESTS_DATA')
    );
  };

  const handleChangeAgencyAccountStatus = () => {
    const agencyId = initialData?._id ?? '';
    let params = { query: '/' + agencyId + '/enable' };
    /** Update Account Status */
    if (!initialData?.agencyStatus) {
      dispatch(
        requestPUTAPISuperadminAgency(params, 'SUPERADMIN_PAUSE_ACCOUNT')
      );
    }
  };

  const handlePauseAgencyAccount = () => {
    const agencyId = initialData?._id ?? '';
    let request = { query: '/' + agencyId + '/disable' };
    if (initialData?.agencyStatus) {
      dispatch(
        requestPUTAPISuperadminAgency(request, 'SUPERADMIN_PAUSE_ACCOUNT')
      );
    }
  };

  const handleDeleteAgencyAccount = () => {
    const agencyId = initialData?._id ?? '';
    let request = {
      query: `/${agencyId}/state/DELETED/set`,
      data: null,
    };
    dispatch(
      requestPUTAPISuperadminAgency(request, 'SUPERADMIN_DELETE_ACCOUNT')
    );
  };

  const handleSaveAgencyDraft = () => {
    const agencyId = initialData?._id ?? '';
    let request = {
      query: agencyId + '/update',
      data: { ...currentRequest },
    };
    dispatch(requestPUTAPIAgency(request, 'UPDATE_AGENCY_DETAILS'));
  };

  const handleSendAgencyDocusign = () => {
    const agencyId = initialData?._id ?? '';
    let request = {
      query: '/' + agencyId,
      data: null,
    };
    dispatch(
      requestPOSTAPISuperadmin(request, 'SUPERADMIN_SEND_DOCUSIGN_AGENCY')
    );
    setDocusignRequest(false);
  };

  const handleReSendAgencyDocusign = (data) => {
    const agencyId = initialData?._id ?? '';
    let request = {
      query: '/' + agencyId,
      data: null,
    };
    dispatch(
      requestPOSTAPISuperadmin(request, 'SUPERADMIN_RESEND_DOCUSIGN_AGENCY')
    );
    setResendDocusignRequest(false);
  };

  const handleUpdateChangeRequest = (data) => {
    const changeIntentReference = data?._id ?? '';
    let request = {
      query: `/${changeIntentReference}/state/DONE/set`,
      data: null,
    };
    dispatch(
      requestPUTAPISuperadminAgency(
        request,
        'SUPERADMIN_UPDATE_CHANGE_REQUESTS'
      )
    );
  };

  const handlePostAgencyReference = (data) => {
    let request = {
      query: '/' + data?.oldAgencyReference + '/' + data?.newAgencyReference,
      data: null,
    };
    dispatch(
      requestPOSTAPISuperadmin(request, 'SUPERADMIN_SEND_OFFICE_REFERENCE')
    );
  };

  const handleOfficeDetails = (data) => {
    let request = {
      query: '/' + data,
      data: {},
    };
    handleClearOfficeDetails();
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_OFFICE_DATA'));
  };

  /** Redux Actions */
  const handleClearStatus = () => {
    setTimeout(() => setShowMessage({ ...showMessage, open: false }), 2000);
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_STATUS'));
  };

  const handleClearAgencyData = () => {
    dispatch(superadminActions(null, 'SUPERADMIN_GET_AGENCY_DATA', 'CLEAR'));
  };

  const handleClearChangeRequestData = () => {
    dispatch(
      superadminActions(null, 'SUPERADMIN_GET_CHANGE_REQUESTS_DATA', 'CLEAR')
    );
  };

  const handleClearError = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'));
  };

  const handleClearOfficeDetails = () => {
    dispatch(superadminActions(null, 'SUPERADMIN_GET_OFFICE_DATA', 'CLEAR'));
  };

  return (
    <div className={classes.root}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={loading} />
      <Snackybar
        open={showMessage.open}
        setOpen={handleMessage}
        type={showMessage.type}
        message={showMessage.message}
      />
      <ConfirmDialog
        isOpen={confirmDialog.open}
        confirmCallback={() => handleConfirmDialogCallback()}
        closeCallback={() => handleConfirmDialogClose(false)}
        showLogo={true}
        align={'center'}
        title={confirmDialog.title}
        text={confirmDialog.message}
        continueButton={confirmDialog.continue}
      />
      <SuperBreadCrumbs
        section={'Manage Change Request Information'}
        crumbsLinks={CRUMBS}
      />
      {AGENCY &&
        AGENCY?.data?.entity &&
        CHANGE_REQUESTS &&
        CHANGE_REQUESTS?.data?.entities && (
          <AgencyDetailsForm
            initialData={AGENCY?.data?.entity}
            packages={packagesv2}
            agencies={AGENCIES?.data?.entities}
            offices={OFFICES_LIST?.agencyList}
            match={match}
            handleConfirmDialogOpen={handleConfirmDialogOpen}
            isChangeRequests={true}
            changeRequestsData={CHANGE_REQUESTS?.data?.entities[0]}
            requestUploadImage={handleUploadImage}
            requestClearUploadImage={() => handleClearUploadsStatuses}
            uploadedImage={AGENCY_UPLOAD?.response?.uploadedFiles[0]}
            handleOfficeDetails={handleOfficeDetails}
            office={office}
            setOffice={setOffice}
            officeId={officeId}
            setOfficeId={setOfficeId}
            officesHandler={officesHandler}
          />
        )}
    </div>
  );
};
export default withStyles(styles)(SuperadminChangeRequestsDetails);
