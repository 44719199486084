import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SelectPackage from './SelectPackage';
import {
  getPricePackages,
  getPricePackagesV2,
  getClientSecret,
  getPaymentMethodIntent,
  getStatus,
} from 'store/selectors/billing.selectors';
import { billingActions } from 'store/actions/billing.actions';
import {
  requestGETAPIBilling,
  requestGETAPIBillingV2,
  requestPOSTAPIBilling,
} from 'store/api/billing.api';
import {
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';
import AlertNotification from '../../Web/Form/AlertNotification';

import StripePaymentForm from 'components/Common/Billing/StripePaymentForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  CheckCircle,
  WarningRounded,
  Error,
  CachedRounded,
} from '@material-ui/icons';
import CustomDatePicker from '../../Web/Filter/CustomDatePicker';
import { scrollToRef } from 'components/Web/ScrollEvents';
import { useRouterChange } from 'utils/router.utils';

const API_KEY = process?.env?.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const ACCOUNT_ID = process?.env?.REACT_APP_STRIPE_ACCOUNT_ID;
const stripePromise = loadStripe(API_KEY, { stripeAccount: ACCOUNT_ID });

const styles = (theme) => ({
  btn: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: theme.spacing(0.45, 2),
    width: 150,
    height: 48,
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
  },
  btnsWrap: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  btnTitleWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  btnContainer: {
    borderRadius: 40,
    border: 'solid 1px #f5f5f5',
    display: 'inline-block',
    marginTop: theme.spacing(2.75),
    marginRight: theme.spacing(3),
    '& button:disabled': {
      color: theme.palette.common.black,
    },
    [theme.breakpoints.down('sm')]: {
      '& button:first-child': {
        marginBottom: theme.spacing(1),
      },
    },
    backgroundColor: '#f5f5f5',
  },
  subtitle: {
    fontSize: 24,
    fontWeight: 400,
  },
  packageGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  statusWrap: {
    textAlign: 'center',
    minHeight: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardRows: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(3),
  },
  cardsWrapper: {
    padding: theme.spacing(0.5, 0),
  },
  dateWrap: {
    maxWidth: 230,
    padding: theme.spacing(1.25, 1, 1, 0),
    '& fieldset': {
      borderRadius: 20,
    },
    '& input': {
      textAlign: 'center',
    },
  },
  packageTitle: {
    color: 'black',
    fontSize: 16,
    fontFamily: 'Montserrat',
    fontWeight: '600',
    wordWrap: 'break-word',
  },
  packagePrice: {
    color: 'black',
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: '400',
    wordWrap: 'break-word',
    marginTop: -5,
  },
});

const AgencyPaymentSection = (props) => {
  const {
    classes,
    paymentStatus,
    message,
    customerData,
    redirectUrl,
    formStep,
    history,
  } = props;
  const [packages, setPackages] = useState(null);
  const [packagesv2, setPackagesV2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [checkout, setCheckout] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);

  const defaultNotification = { status: false, options: null };
  const [isNotification, setIsNotification] = useState(defaultNotification);
  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [subscriptionTerm, setSubscriptionTerm] = useState('month');
  const [selectedPackage, setSelectedPackage] = useState(null);

  const SelectPackageRef = useRef(null);
  const PaymentFormRef = useRef(null);

  const params = new URLSearchParams(history?.location?.search);
  const atStep3 = params?.get('step');

  const calculatedValue = useMemo(() => {
    let new_date = moment(startDate, 'DD-MM-YYYY').add('days', 120);
    let day = new_date.format('DD');
    let month = new_date.format('MM');
    let year = new_date.format('YYYY');
    return day + '/' + month + '/' + year;
  }, [startDate]);

  const [routerChange] = useRouterChange();

  const handleStartDate = (data) => {
    setStartDate(data);
  };

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
    loader: 'always',
  };

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification);
  };

  const dispatch = useDispatch();
  const PACKAGES = useSelector(getPricePackages);
  const PACKAGESV2 = useSelector(getPricePackagesV2);
  const SETUP_CLIENTSECRET = useSelector(getClientSecret);
  const ADD_PAYMENTMETHOD_SECRET = useSelector(getPaymentMethodIntent);
  const BILLING_STATUS = useSelector(getStatus);

  const handleSelectPackage = (type) => {
    setSelectedPackage(type);

    let getPackage = packages.filter((item) => item.propertyPlanType === type);
    let priceId = getPackage[0]?.productPriceList?.filter(
      (price) => price.type === subscriptionTerm
    )[0].id;

    if (priceId && !customerData?.subscription) {
      const request = {
        priceId: priceId,
        email: customerData?.adminEmail,
        name: customerData?.firstName + ' ' + customerData?.lastName,
        agencyReference: customerData?.agencyReference,
        startDate: startDate,
      };
      apiRequestGetClientSecret(request);
    }
  };

  const handleGetPackageClear = () => {
    dispatch(billingActions(null, 'STRIPE_GET_PRICES', 'CLEAR'));
  };

  const handleGetPackageClearV2 = () => {
    dispatch(billingActions(null, 'PACKAGES_GET_PRICES', 'CLEAR'));
  };

  const handleGetClientSecretClear = () => {
    dispatch(billingActions(null, 'STRIPE_POST_CLIENTSECRET', 'CLEAR'));
  };

  const apiRequestGetPricePackages = () => {
    setIsLoading(true);
    const request = {
      query: null,
      data: null,
    };
    handleGetPackageClear();
    dispatch(requestGETAPIBilling(request, 'STRIPE_GET_PRICES', 'REQUEST'));
  };

  const apiRequestGetPricePackagesV2 = () => {
    setIsLoading(true);
    const request = {
      query: null,
      data: null,
    };
    handleGetPackageClearV2();
    dispatch(requestGETAPIBillingV2(request, 'PACKAGES_GET_PRICES', 'REQUEST'));
  };

  const apiRequestGetClientSecret = (params) => {
    setIsLoading(true);
    const request = {
      query: null,
      data: params,
    };
    handleGetClientSecretClear();
    dispatch(
      requestPOSTAPIBilling(request, 'STRIPE_POST_CLIENTSECRET', 'REQUEST')
    );
  };

  const apiRequestGetPaymentMethodSecret = () => {
    setIsLoading(true);
    const request = {
      query: null,
      data: {
        sourceActorReference: customerData?.subscription?.sourceActorReference,
      },
    };
    handleGetSetupPaymentMethodIntentClear();
    dispatch(
      requestPOSTAPIBilling(
        request,
        'STRIPE_POST_PAYMENTMETHODINTENT',
        'REQUEST'
      )
    );
  };

  const handleGetSetupPaymentMethodIntentClear = () => {
    dispatch(billingActions(null, 'STRIPE_POST_PAYMENTMETHODINTENT', 'CLEAR'));
  };

  useEffect(() => {
    if (SETUP_CLIENTSECRET) {
      if (atStep3) {
        if (
          SETUP_CLIENTSECRET.status ===
          'billing/STRIPE_POST_CLIENTSECRET_REQUEST'
        ) {
          //  Loading...
        }
        if (
          SETUP_CLIENTSECRET.status ===
          'billing/STRIPE_POST_CLIENTSECRET_SUCCESS'
        ) {
          routerChange('/agency/registration/status?reg_status=success');
        }
        if (
          SETUP_CLIENTSECRET.status ===
          'billing/STRIPE_POST_CLIENTSECRET_FAILED'
        ) {
          routerChange('/agency/registration/status?reg_status=failed');
        }
      }
    }
  }, [SETUP_CLIENTSECRET]);

  useEffect(() => {
    if (!packages && !paymentStatus && !message) {
      apiRequestGetPricePackages();
    }

    if (!packagesv2 && !paymentStatus && !message) {
      apiRequestGetPricePackagesV2();
    }

    if (
      customerData &&
      customerData?.subscription &&
      !paymentStatus &&
      !message
    ) {
      apiRequestGetPaymentMethodSecret();
      if (customerData?.subscription?.billingPeriodType) {
        setSubscriptionTerm(
          customerData?.subscription?.billingPeriodType === 'MONTHLY'
            ? 'month'
            : customerData?.subscription?.billingPeriodType === 'YEARLY'
            ? 'year'
            : null
        );
      }
    }
  }, []);

  useEffect(() => {
    if (PACKAGES?.packages) {
      setPackages(PACKAGES?.packages);
    }

    if (PACKAGES) {
      if (
        PACKAGES.status === 'billing/STRIPE_GET_PRICES_SUCCESS' ||
        PACKAGES.status === 'billing/STRIPE_GET_PRICES_FAILED'
      ) {
        setIsLoading(false);
      }
    }
  }, [PACKAGES]);

  useEffect(() => {
    if (PACKAGESV2?.packages) {
      setPackagesV2(PACKAGESV2?.packages);
    }

    if (PACKAGESV2) {
      if (
        PACKAGESV2.status === 'billing/PACKAGES_GET_PRICES_SUCCESS' ||
        PACKAGESV2.status === 'billing/PACKAGES_GET_PRICES_FAILED'
      ) {
        setIsLoading(false);
      }
    }
  }, [PACKAGESV2]);

  useEffect(() => {
    if (ADD_PAYMENTMETHOD_SECRET) {
      if (
        ADD_PAYMENTMETHOD_SECRET.status ===
          'billing/STRIPE_POST_PAYMENTMETHODINTENT_SUCCESS' ||
        ADD_PAYMENTMETHOD_SECRET.status ===
          'billing/STRIPE_POST_PAYMENTMETHODINTENT_FAILED'
      ) {
        setIsLoading(false);
      }

      if (
        ADD_PAYMENTMETHOD_SECRET.status ===
        'billing/STRIPE_POST_PAYMENTMETHODINTENT_SUCCESS'
      ) {
        setClientSecret(ADD_PAYMENTMETHOD_SECRET?.data?.client_secret);
      }

      if (
        ADD_PAYMENTMETHOD_SECRET.status ===
        'billing/STRIPE_POST_PAYMENTMETHODINTENT_FAILED'
      ) {
        setIsNotification({
          status: true,
          options: {
            severity: 'error',
            message:
              'Error getting payment method authentication. Please try again after few seconds.',
          },
        });
      }
    }
  }, [ADD_PAYMENTMETHOD_SECRET]);

  useEffect(() => {
    if (SETUP_CLIENTSECRET) {
      if (
        SETUP_CLIENTSECRET.status ===
          'billing/STRIPE_POST_CLIENTSECRET_SUCCESS' ||
        SETUP_CLIENTSECRET.status === 'billing/STRIPE_POST_CLIENTSECRET_FAILED'
      ) {
        setIsLoading(false);
      }

      if (
        SETUP_CLIENTSECRET.status === 'billing/STRIPE_POST_CLIENTSECRET_SUCCESS'
      ) {
        setClientSecret(SETUP_CLIENTSECRET?.data?.clientSecret);
      }

      if (
        SETUP_CLIENTSECRET.status === 'billing/STRIPE_POST_CLIENTSECRET_FAILED'
      ) {
        setIsNotification({
          status: true,
          options: {
            severity: 'error',
            message:
              'Error authenticating payment gateway. Please try again after few seconds.',
          },
        });
      }
    }
  }, [SETUP_CLIENTSECRET]);

  return (
    <>
      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
      {!customerData?.subscription && (
        <Card className={classes.cardRows} elevation={1}>
          <CardContent>
            {formStep && formStep === 3 && (
              <>
                <Typography variant='h4' className={classes.subtitle}>
                  <strong>Step 3: Subscription Details</strong>
                </Typography>
                <Divider style={{ margin: '20px auto' }} />
              </>
            )}

            <Typography variant='h4' className={classes.subtitle}>
              Select subscription start date
            </Typography>
            <br />
            <div style={{ paddingTop: 10, marginBottom: 10 }}>
              <Typography variant={'body2'}>
                Once your billing date begins, your subscription will be charged
                automatically to your credit card.
              </Typography>
              <Typography variant={'body2'}>
                Your billing date will be discussed and determined with
                PropertyMate before your Advertising Agreement is executed.
              </Typography>
              <Typography variant={'body2'}>
                We impose a surcharge on credit cards that is not greater than
                our cost of acceptance.
              </Typography>
            </div>
            <Grid container spacing={0}>
              <Grid item className={classes.dateWrap}>
                <CustomDatePicker
                  isForm={true}
                  value={
                    customerData?.subscription?.startDateString ?? startDate
                  }
                  handleDate={handleStartDate}
                  isClearFilter={false}
                  minDate={customerData?.subscription?.startDateString ?? today}
                  style={{
                    pointerEvents:
                      customerData?.subscription || selectedPackage
                        ? 'none'
                        : 'initial',
                    marginTop: 5,
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {!customerData?.subscription && (
        <Card className={classes.cardRows} elevation={1}>
          <CardContent>
            {/* <br /> */}
            <Typography variant='h4' className={classes.subtitle}>
              Subscription payment method
            </Typography>
            <div style={{ paddingTop: 10, marginBottom: 10 }}>
              <Typography variant={'body2'}>
                {
                  'Nominate your preferred subscription method (monthly/annually)'
                }
              </Typography>
            </div>
            <Grid container spacing={0}>
              <br />

              <Grid>
                <div className={classes.btnContainer}>
                  <Button
                    variant={subscriptionTerm === 'month' ? 'contained' : null}
                    style={{
                      backgroundColor:
                        subscriptionTerm === 'month' ? '#35C0CA' : '#f5f5f5',
                    }}
                    onClick={() => {
                      setSubscriptionTerm('month');
                      scrollToRef(SelectPackageRef, true);
                    }}
                    className={classes.btn}
                    elevation={0}
                    disabled={customerData?.subscription ? true : false}
                  >
                    <div className={classes.btnTitleWrap}>
                      <strong className={classes.packageTitle}>Monthly</strong>
                      <span className={classes.packagePrice}>$100/month*</span>
                    </div>
                  </Button>
                  <Button
                    variant={subscriptionTerm === 'year' ? 'contained' : null}
                    style={{
                      backgroundColor:
                        subscriptionTerm === 'year' ? '#35C0CA' : '#f5f5f5',
                    }}
                    onClick={() => {
                      setSubscriptionTerm('year');
                      scrollToRef(SelectPackageRef, true);
                    }}
                    className={classes.btn}
                    elevation={0}
                    disabled={customerData?.subscription ? true : false}
                  >
                    <div className={classes.btnTitleWrap}>
                      <strong className={classes.packageTitle}>Annual</strong>
                      <span className={classes.packagePrice}>$1000/year*</span>
                    </div>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {paymentStatus && message && (
        <Card className={classes.cardRows} elevation={1}>
          <CardContent>
            <div className={classes.statusWrap}>
              {paymentStatus === 'success' && (
                <div>
                  <CheckCircle color={'primary'} fontSize={'large'} />
                  <Typography variant={'h5'}>Nice work</Typography>
                  <Typography variant={'body1'}>
                    You&rsquo;ve done the hard yards, now it&rsquo;s our turn.
                  </Typography>
                  {/*                   <Typography variant={'body1'}>
                    Keep an eye on your inbox, confirmation of your PropertyMate
                    registration is imminent.
                  </Typography> */}
                  <Typography variant={'body1'}>
                    We'll be in touch soon to ask a few more questions and
                    finalise your account.
                  </Typography>
                </div>
              )}
              {paymentStatus === 'processing' && (
                <div>
                  <CachedRounded color={'secondary'} fontSize={'large'} />

                  <Typography variant={'h5'}>{message}</Typography>
                </div>
              )}
              {paymentStatus === 'unsuccessful' && (
                <div>
                  <WarningRounded color={'error'} fontSize={'large'} />
                  <Typography variant={'h5'}>{message}</Typography>
                </div>
              )}
              {paymentStatus === 'error' && (
                <div>
                  <Error color={'error'} fontSize={'large'} />
                  <Typography variant={'h5'}>{message}</Typography>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      )}
      <div ref={SelectPackageRef}></div>
      {packages && !customerData?.subscription && (
        <Card className={classes.cardRows} elevation={1}>
          <CardContent>
            <SelectPackage
              selectCallback={handleSelectPackage}
              packages={packages}
              isLoading={false}
              checkout={checkout}
              selectedPackage={
                customerData?.subscription?.subscriptionType ?? selectedPackage
              }
              subscriptionTerm={subscriptionTerm}
              isCustomerSubscribed={customerData?.subscription ? true : false}
            />
          </CardContent>
        </Card>
      )}

      <div ref={PaymentFormRef}></div>
      {clientSecret && !paymentStatus && !message && !atStep3 && (
        <>
          <Card className={classes.cardRows} elevation={1}>
            <CardContent>
              <Typography variant='h4' className={classes.subtitle}>
                Payment Details
              </Typography>
              <Typography
                variant={'body2'}
                style={{ margin: '10px 2px', color: '#6d6e78' }}
              >
                Payment is for monthly/annual subscription only.
              </Typography>
              <Typography
                variant={'body2'}
                style={{ margin: '10px 2px', color: '#6d6e78' }}
              >
                All property listings will be invoiced individually and won’t be
                charged to this credit card.
              </Typography>
              <Grid container spacing={4} className={classes.packageGrid}>
                <Grid item md={6}>
                  <Elements options={options} stripe={stripePromise}>
                    <StripePaymentForm
                      buttonLabel={'Subscribe Now'}
                      redirectPath={redirectUrl ?? '/agency/sign-up/?step=3'}
                      readyCallback={() => {
                        scrollToRef(PaymentFormRef, true);
                      }}
                    />
                  </Elements>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};
export default withStyles(styles)(AgencyPaymentSection);
