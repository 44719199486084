import BIGGINSCOTTLOGO from '../assets/images/biggin-scott-logo.png';
import BUXTONSLOGO from '../assets/images/buxton-logo.png';
import FIRSTNATIONALLOGO from '../assets/images/first-national-real-estate-logo.png';
import ABERCROMBIELOGO from '../assets/images/abercromby-logo-white.png';
import KAYBURTONLOGO from '../assets/images/kay-burton.png';
import WOODARDSLOGO from '../assets/images/woodards-logo.png';
import JELLISCRAIGLOGO from '../assets/images/JCR_logo_oneline_wht.png';
import HODGESLOGO from '../assets/images/hodges_positive_grey.png';
import FLETCHERSLOGO from '../assets/images/fletchers-logo-r.png';
import BELLEPROPERTYLOGO from '../assets/images/belle-property.png';
const WILSONLOGO =
  'https://res.cloudinary.com/dyc2qhrgg/image/upload/v1648077668/Agency/wilson_vnprc2.jpg';
const RTEDGARLOGO =
  'https://res.cloudinary.com/dyc2qhrgg/image/upload/e_trim/v1649286872/Agency/RTEDgar_pxjjw0.webp';
const CHISHOLMGAMONLOGO =
  'https://res.cloudinary.com/dyc2qhrgg/image/upload/v1652337003/Agency/chisholmandgammon.png';
const MARSHALLWHITELOGO =
  'https://res.cloudinary.com/dyc2qhrgg/image/upload/v1652320676/Agency/marshallwhite.png';
const RAYWHITELOGO =
  'https://res.cloudinary.com/dyc2qhrgg/image/upload/v1652160489/Agency/raywhite.jpg';
const LAINGANDSIMMONSLOGO =
  'https://res.cloudinary.com/dyc2qhrgg/image/upload/v1652160185/Agency/laingandsimmons.png';

/** Constants Here */
export const SCREEN_TO_MOBILE_VERSION_WIDTH = 1024;

export const SOCIAL_MEDIA_BUTTONS = [
  {
    icon: '2DCAppleIcon',
    label: 'Apple',
    type: 'Apple',
  },
  {
    icon: '2DCFacebookIcon',
    label: 'Facebook',
    type: 'Facebook',
  },
  {
    icon: '2DCGoogleIcon',
    label: 'Google',
    type: 'Google',
  },
];

export const SIGNIN_VALIDATION_CONFIG = {
  fields: {
    email: {
      isRequired: { message: 'Email is required' },
      isEmail: { message: 'Email is invalid' },
    },
    password: {
      isRequired: { message: 'Password is required' },
    },
  },
  onSubmit: (data) => {
    if (data.isFormValid) {
      console.log('Submitted', data);
    } else {
      console.log('Please fix the errors of the form.');
    }
  },
  showErrors: 'change',
};

export const AGENT_CREATE_PROPERTY_FIELDS_CONFIG = (fields) => ({
  fields: {
    vendorsEmail: {
      // isRequired: { message: 'Vendor Email is required' },
      isEmail: { message: 'Vendor Email is invalid' },
    },
    vendorsPhone: {
      // isRequired: { message: 'Vendor Phone is required' },
      isMinLength: {
        length: 10,
        message: 'Vendor Phone must be at least 10 digits',
      },
    },
  },
  onSubmit: (e) => {
    console.log('EXECUTE SUBMIT', e);
  },
  showErrors: 'change',
});

export const REGISTER_VALIDATION_CONFIG = (fields) => ({
  fields: {
    firstName: {
      isRequired: { message: 'First Name is required' },
    },
    lastName: {
      isRequired: { message: 'Last Name is required' },
    },
    email: {
      isRequired: { message: 'Email is required' },
      isEmail: { message: 'Email is invalid' },
    },
    password: {
      isRequired: { message: 'Password is required' },
      isStrongPassword: { message: 'Invalid password format' },
      isMinLength: {
        length: 8,
        message: 'Password must be at least 8 characters.',
      },
      // hasUppercase: {message: 'Password must have one capital letter.'},
      // hasSpecialChar: { message: 'Password must have at least one special character.' },
      // hasDigit: { message: 'Password must have a number.' }
    },
    confirmpassword: {
      isRequired: { message: 'Confirm Password is required' },
      isStrongPassword: { message: 'Invalid password format' },
      isMinLength: {
        length: 8,
        message: 'Password must be at least 8 characters.',
      },
      isEqual: { value: fields.password, message: "Password doesn't match." },
    },
    code: {
      isRequired: { message: 'Code is required' },
      isMinLength: { length: 6, message: 'Code must be at least 6 characters' },
    },
    phone: {
      isRequired: { message: 'Phone is required' },
      isValidAuPhoneNumber: { message: 'Phone number is invalid' },
      // isMinLength: {length: 12, message: 'Phone number is invalid'}
      isMinLength: {
        length: 10,
        message: 'Phone number needs to be 10 digits',
      },
    },
  },
  onSubmit: (e) => {
    console.log('EXECUTE SUBMIT', e);
  },
  showErrors: 'change',
});

export const CONTACT_US_VALIDATION_CONFIG = {
  fields: {
    email: {
      isRequired: { message: 'Email is required' },
      isEmail: { message: 'Email is invalid' },
    },
    postcode: {
      isRequired: { message: 'Postcode is required' },
      isNumber: { message: 'Postcode must be all numbers' },
      isMinLength: { length: 4, message: 'Postcode requires 4 digits' },
    },
    firstName: {
      isRequired: { message: 'First Name is required' },
    },
    lastName: {
      isRequired: { message: 'Last Name is required' },
    },
    phone: {
      isMinLength: { length: 10, message: 'Phone number is invalid' },
    },
  },
  showErrors: 'change',
};

export const FORGOT_PASSWORD_VALIDATION_CONFIG = (fields) => ({
  fields: {
    email: {
      isEmail: { message: 'Email is invalid' },
      isRequired: { message: 'Email is required' },
    },
    password: {
      isRequired: { message: 'Password is required' },
      isStrongPassword: { message: 'Invalid password format' },
      isMinLength: {
        length: 8,
        message: 'Password must be at least 8 characters.',
      },
    },
    confirmpassword: {
      isRequired: { message: 'Confirm Password is required' },
      isEqual: { value: fields.password, message: "Password doesn't match." },
    },
    code: {
      isRequired: { message: 'Code is required' },
      isMinLength: { length: 6, message: 'Code must be at least 6 characters' },
    },
  },
  onSubmit: (e) => {
    console.log('EXECUTE SUBMIT', e);
  },
  showErrors: 'change',
});

export const AGENCY_REGISTER_VALIDATION_CONFIG = (fields) => ({
  fields: {
    companyName: {
      isRequired: { message: 'Your Company Name is required' },
    },
    companyWebsite: {
      isRequired: { message: 'Your Company Website is required' },
      isURL: { message: 'Invalid URL format of company website' },
    },
    businessNumber: {
      isRequired: { message: 'ABN is required' },
      isMinLength: { length: 11, message: 'Business number is invalid' },
    },
    suburb: {
      isRequired: { message: 'Suburb is required' },
    },
    firstName: {
      isRequired: { message: 'First Name is required' },
    },
    lastName: {
      isRequired: { message: 'Last Name is required' },
    },
    email: {
      isEmail: { message: 'Email is invalid' },
      isRequired: { message: 'Email is required' },
    },
    adminEmail: {
      isEmail: { message: 'Admin Email is invalid' },
      isRequired: { message: 'Admin Email is required' },
    },
    phone: {
      isRequired: { message: 'Mobile is required' },
      isMinLength: { length: 10, message: 'Mobile is invalid' },
    },
    crm: {
      isRequired: { message: 'Bulk Uploader Provider is required' },
    },
    manualCrm: {
      isRequired: { message: 'Bulk Uploader Provider is required' },
    },
    office: {
      isRequired: { message: 'Office is required' },
    },
    authCompanyName: {
      isRequired: { message: 'Your Company Name is required' },
    },
    authFirstName: {
      isRequired: { message: 'First Name is required' },
    },
    authLastName: {
      isRequired: { message: 'Last Name is required' },
    },
    authEmail: {
      isRequired: { message: 'Email is required' },
      isEmail: { message: 'Email is invalid' },
    },
    authPhone: {
      isRequired: { message: 'Mobile Number is required' },
      isMinLength: { length: 10, message: 'Phone number is invalid' },
    },
    hdyfu: {
      isRequired: { message: 'How did you find us is required' },
    },
    manualHdyfu: {
      isRequired: { message: 'How did you find us is required' },
    },
    postcode: {
      isRequired: { message: 'Postcode is required' },
    },
    isPrivacyChecked: {
      isRequired: { message: 'You must agree to our Privacy Policy' },
    },
    isTermsChecked: {
      isRequired: { message: 'You must agree to our Terms of Use' },
    },
  },
  onSubmit: (e) => {
    console.log('EXECUTE SUBMIT', e);
  },
  showErrors: 'change',
});

export const STATES = ['ACT', 'VIC', 'NSW', 'SA', 'QLD', 'WA', 'NT'];

export const PERIOD = [
  { label: 'Monthly', id: 1, value: 'MONTHLY' },
  { label: 'Yearly', id: 2, value: 'YEARLY' },
];

export const HOW_DID_YOU_FIND_US = [
  'Search engine',
  'TV',
  'Radio',
  'Facebook',
  'Instagram',
  'Twitter',
  'LinkedIn',
  'Event or conference',
  'Press Release',
  'News article',
  'Word of mouth',
  'Other',
];

export const CRMS = [
  'Ljhooker',
  'Mydesktop',
  'Renet',
  'Century21',
  'Irene',
  'Harcourts',
  'Agentbox',
  'Subtle Difference',
  'Core Web',
  'Eagle Software',
  'Apx',
  'AgentPoint',
  'Console',
  'MultiArray',
  'Aro Software',
  'Blueribbon',
  'Boxdice',
  'Infusionsoft',
  'Locked On',
  'Au1Net',
  'Property Base',
  'Rexsoftware',
  'Salesforce',
  'Sherlock',
  'Inhabit',
  'Pro List',
  'iProperty',
  'REST / Rockend',
  'ListOnce / WebIT',
  'IWON',
  'Photosigns',
  'Mantis',
  'Realoz',
  'OSL',
  'EAC / Redsquare',
  'Zenu',
  'Premises Aus',
  'Property Compass',
  'AgentCRM',
  'Cloudpm',
  'Vault RE',
  'Other',
];

export const AGENTS_CONST = (fields) => ({
  fields: {
    email: {
      isEmail: { message: 'Email is invalid' },
      isRequired: { message: 'Email is required' },
    },
    // first_name: {
    //     isRequired: {message: 'First name is required'}
    // },
    // last_name: {
    //     isRequired: {message: 'Last name is required'}
    // },
    // phone: {
    //     isMinLength: {length: 12, message: 'Phone must be in this format (0#) #### ####'}
    // }
  },
  onSubmit: (e) => {
    console.log('EXECUTE SUBMIT', e);
  },
  showErrors: 'change',
});

export const DEFAULT_AGENCIES = {
  'Biggin & Scott': [
    'BIGGINSCOTT',
    'pm_1462',
    '1198',
    '2009',
    '2385',
    '2449',
    '2730',
    '2732',
    '2824',
    'XBSRIC',
    'JOEDAY',
    'XBSELS',
    'MPGORV',
    'XBSPRA',
    'PEKMXM',
    'XBSSTK',
    'XBPDEF',
    'ROXMIT',
    'MOFYOB',
    'JYEUVQ',
    'SBUSEY',
    'XBSKEN',
    'XBPCRB',
    'BIGGINSCOTT_Melbourne',
  ],
  Buxton: [
    'Buxtons_DingleyVillage',
    'Buxtons_StKilda',
    'Buxtons_AlbertPark',
    'Buxtons_Ashburton',
    'Buxtons_Bentleigh',
    'Buxtons_Brighton',
    'Buxtons_GeelongEast',
    'Buxtons_HamptonEast',
    'Buxtons_Highton',
    'Buxtons_Mentone',
    'Buxtons_Newtown',
    'Buxtons_Oakleigh',
    'Buxtons_Sandringham',
    'Buxtons_MountWaverley',
    'Buxtons_Ballarat',
    'Buxtons_Camberwell',
    'Buxtons_BoxHill',
    'Buxtons_Keysborough',
    'Buxtons_Chelsea',
    'Buxtons_GeelongNorth',
    'Buxtons_Leopold',
    'Buxtons_Stonnington',
  ],
  'First National': ['FNKHB1796', 'FNKHL1796', 'FNKHM1796', 'FNKHP1796'],
  Abercrombie: ['ABERCROMBIE'],
  'Kay & Burton': [
    'KBA9998',
    'KBB9998',
    'KBF9998',
    'KBP9998',
    'KBSY9998',
    'KBH9998',
  ],
  'Jellis Craig': [
    'JC_Boroondara',
    'JC_CARNEGIE',
    'JC_BENTLEIGH',
    'JC_BRIGHTON',
    'JC_Whitehorse',
    'JC_Eltham',
    'JC_Greensborough',
    'JC_Ivanhoe',
    'JC_Rosanna',
    'JC_Kensington',
    'JC_Essendon',
    'JC_Daylesford',
    'JC_Ballarat',
    'JC_Woodend',
    'JC_Castlemaine',
    'JC_PMBRUNSWICK',
    'PM_JCFITZROY',
  ],
  Woodards: [
    'WSY2328',
    '9863248',
    '8956543',
    '8856926',
    '8468685',
    '7654987',
    '6561593',
    '6316549',
    '5646945',
    '3892349',
    '2984651',
    '1654896',
    '165489',
    'Wood_Essendon',
    'Wood_Gisborne',
    'Wood_Keilor',
    'Wood_PascoeV',
    'Wood_Sunbury',
    '8675643',
  ],
  Hodges: ['h91456', 'h32156'],
  Fletchers: [
    '15913',
    '22749',
    '15914',
    '30709',
    '15062',
    '26280',
    '15915',
    '30345',
    '26413',
  ],
  'Belle Property': ['BS6401', 'BPAP6401', 'BPCM6401', 'REIP-205316'],
  Wilson: ['PMSTKILDA', 'PMCAULFIELD', 'Wilson_GlenEira', 'Wilson_PortPhilip'],
  'RT Edgar': ['160', '158', '32164', '19615', '166', '161', '168'],
  'Chisholm&Gamon': ['CGREE2518', 'CGREPM2518'],
  'Marshall White': [
    'MW_Balwyn',
    'MW_Bayside',
    'MW_Hawthorn',
    'MW_Manningham',
    'MW_MorningtonPeninsula',
    'MW_PortPhillip',
    'MW_Stonnington',
  ],
  RayWhite: [],
  'Laing + Simmons': [],
};

export const LOGO_OBJECT = {
  'Biggin & Scott': BIGGINSCOTTLOGO,
  Buxton: BUXTONSLOGO,
  'First National': FIRSTNATIONALLOGO,
  Abercrombie: ABERCROMBIELOGO,
  'Kay & Burton': KAYBURTONLOGO,
  Woodards: WOODARDSLOGO,
  'Jellis Craig': JELLISCRAIGLOGO,
  Hodges: HODGESLOGO,
  Fletchers: FLETCHERSLOGO,
  'Belle Property': BELLEPROPERTYLOGO,
  Wilson: WILSONLOGO,
  'RT Edgar': RTEDGARLOGO,
  'Chisholm&Gamon': CHISHOLMGAMONLOGO,
  'Marshall White': MARSHALLWHITELOGO,
  RayWhite: RAYWHITELOGO,
  'Laing + Simmons': LAINGANDSIMMONSLOGO,
};

export const HEX_OBJECT = {
  'Biggin & Scott': '#092564',
  Buxton: '#000000',
  'First National': '#00467e',
  Abercrombie: '#717173',
  'Kay & Burton': '#FFFFFF',
  'Jellis Craig': '#1D252D',
  Woodards: '#001535',
  Hodges: '#53565A',
  Fletchers: '#FFFFFF',
  'Belle Property': '#386351',
  Wilson: '#FFFFFF ',
  'RT Edgar': '#020116',
  'Chisholm&Gamon': '#FFFFFFF',
  'Marshall White': '#0D1D34',
  RayWhite: '#FFE512',
  'Laing + Simmons': '#003246',
};

export const getLogoByAgencyId = (object, id) => {
  return Object.keys(object).find((key) =>
    object[key].map((i) => i.toLowerCase()).includes(id?.toLowerCase())
  );
};

export const getAgencyHexByAgencyId = (object, id) => {
  return Object.keys(object).find((key) =>
    object[key].map((i) => i.toLowerCase()).includes(id?.toLowerCase())
  );
};

export const getAgencyNameByAgencyId = (id) => {
  return Object.keys(DEFAULT_AGENCIES).find((key) =>
    DEFAULT_AGENCIES[key]
      .map((i) => i.toLowerCase())
      .includes(id?.toLowerCase())
  );
};

export const cloudinaryCardTransforms = 'f_webp,q_auto';
export const cloudinaryImageWidth = 'w_1000';
export const cloudinaryImageHeightSmall = 'h_400';

export const PRICE_RANGE = [15000, 15000000];
export const PRICE_RANGE_RENT = [0, 5000];

export const SUBSCRIPTION_TRIAL_DAYS = 90;

export const HOME_DEFAULT_BUTTONS_MOBILE = [
  {
    name: 'Buy',
    icon: 'AttachMoney',
    path: '/buy',
    active: false,
  },
  {
    name: 'Rent',
    icon: 'VpnKey',
    path: '/rent',
    active: false,
  },
  {
    name: 'Sold',
    icon: 'Gavel',
    path: '/sold',
    active: false,
  },
  {
    name: 'Coming Soon',
    icon: 'WatchLater',
    path: '/coming-soon',
    active: false,
  },
  {
    name: 'Off Market',
    icon: 'Lock',
    path: '/off-market',
    active: false,
  },
  {
    name: 'Find My Perfect Property',
    icon: 'ThumbUpAlt',
    path: '/perfect-property',
    active: false,
  },
  /* {
      name: 'Trades & Services',
      icon: 'Build',
      path: '/trades',
      active: false
    } */
];

export const PRICE_MARKS = [
  {
    value: 0,
    scaledValue: 0,
    label: '0',
  },
  {
    value: 25,
    scaledValue: 50000,
    label: '50k',
  },
  {
    value: 50,
    scaledValue: 300000,
    label: '300k',
  },
  {
    value: 75,
    scaledValue: 550000,
    label: '550k',
  },
  {
    value: 100,
    scaledValue: 1000000,
    label: '1M',
  },
  {
    value: 125,
    scaledValue: 1500000,
    label: '1.5M',
  },
  {
    value: 150,
    scaledValue: 2000000,
    label: '2M',
  },
  {
    value: 175,
    scaledValue: 3000000,
    label: '3M',
  },
  {
    value: 200,
    scaledValue: 4000000,
    label: '4M',
  },
  {
    value: 225,
    scaledValue: 5000000,
    label: '5M',
  },
  {
    value: 250,
    scaledValue: 10000000,
    label: '10M',
  },
  {
    value: 275,
    scaledValue: 15000000,
    label: '15M',
  },
];

// export const RENT_PRICE_MARKS = [
//   {
//     value: 0,
//     scaledValue: 0,
//     label: '0',
//   },
//   {
//     value: 25,
//     scaledValue: 100,
//     label: '100',
//   },
//   {
//     value: 50,
//     scaledValue: 200,
//     label: '200',
//   },
//   {
//     value: 75,
//     scaledValue: 300,
//     label: '300',
//   },
//   {
//     value: 100,
//     scaledValue: 400,
//     label: '400',
//   },
//   {
//     value: 125,
//     scaledValue: 500,
//     label: '500',
//   },
//   {
//     value: 150,
//     scaledValue: 700,
//     label: '700',
//   },
//   {
//     value: 175,
//     scaledValue: 800,
//     label: '800',
//   },
//   {
//     value: 200,
//     scaledValue: 1000,
//     label: '1k',
//   },
//   {
//     value: 225,
//     scaledValue: 2000,
//     label: '2k',
//   },
//   {
//     value: 250,
//     scaledValue: 3000,
//     label: '3k',
//   },
//   {
//     value: 275,
//     scaledValue: 5000,
//     label: '5k',
//   },
// ]

export const RENT_PRICE_MARKS = [
  {
    value: 0,
    scaledValue: 0,
    label: '0',
  },
  {
    value: 25,
    scaledValue: 150,
    label: '150',
  },
  {
    value: 50,
    scaledValue: 300,
    label: '300',
  },
  {
    value: 75,
    scaledValue: 450,
    label: '450',
  },
  {
    value: 100,
    scaledValue: 800,
    label: '800',
  },
  {
    value: 125,
    scaledValue: 1000,
    label: '1k',
  },
  {
    value: 150,
    scaledValue: 2000,
    label: '2k',
  },
  {
    value: 175,
    scaledValue: 3000,
    label: '3k',
  },
  {
    value: 200,
    scaledValue: 4000,
    label: '4k',
  },
  {
    value: 225,
    scaledValue: 5000,
    label: '5k',
  },
];

export const DISTANCE_RANGE = [0, 500];

export const DISTANCE_MARKS = [
  {
    value: 0,
    scaledValue: 0,
    label: '0',
  },
  {
    value: 25,
    scaledValue: 50,
    label: '50',
  },
  {
    value: 50,
    scaledValue: 100,
    label: '100',
  },
  {
    value: 75,
    scaledValue: 150,
    label: '150',
  },
  {
    value: 100,
    scaledValue: 200,
    label: '200',
  },
  {
    value: 125,
    scaledValue: 450,
    label: '450',
  },
  {
    value: 150,
    scaledValue: 500,
    label: '500',
  },
];

export const ADS_SET = {
  '400x333': [
    'MER_Digital Ad_PropertyMate.png',
    'MER_Digital Ad_PropertyMate2.png',
    'MER_Digital Ad_PropertyMate3.png',
    'MER_Digital Ad_PropertyMate4.png',
    'MER_Digital Ad_PropertyMate5.png',
    'MER_Digital Ad_PropertyMate6.png',
    'MER_Digital Ad_PropertyMate7.png',
  ],
  '400x800': [
    'MER_Digital Ad_PropertyMate8.png',
    'MER_Digital Ad_PropertyMate9.png',
    'MER_Digital Ad_PropertyMate10.png',
    'MER_Digital Ad_PropertyMate11.png',
  ],
  '213x800': [
    'MER_Digital Ad_PropertyMate12.png',
    'MER_Digital Ad_PropertyMate13.png',
    'MER_Digital Ad_PropertyMate14.png',
  ],
  '400x333Animated': ['MER_DigitalAd_PropertyMate.gif'],
  RedZed300x600: ['RZSTRM_PropertyMate_Test_Ads_300x600.jpg'],
  RedZed160x600: ['RZSTRM_PropertyMate_Test_Ads_160x600.jpg'],
};

export const ADVANCED_PROPERTY_CONSTANTS = [
  {
    id: 0,
    label: 'Parklife',
    sublabel: 'Walk to greenspace within 15 mins',
    key: 'close_to_parks',
    active: false,
  },
  {
    id: 1,
    label: 'Shopping',
    sublabel: 'Drive to retail within 15 mins',
    key: 'close_to_shops',
    active: false,
  },
  {
    id: 2,
    label: 'Primary School',
    sublabel: 'Drive to primary schools within 15 mins',
    key: 'close_to_primary_schools',
    active: false,
  },
  {
    id: 3,
    label: 'Secondary School',
    sublabel: 'Drive to secondary schools within 15 mins',
    key: 'close_to_secondary_schools',
    active: false,
  },
  {
    id: 4,
    label: 'Cafe',
    sublabel: 'Drive to cafes in 5 minutes',
    key: 'close_to_cafes',
    active: false,
  },
  {
    id: 5,
    label: 'Transport',
    sublabel: 'Walk to public transport within 20min',
    key: 'close_to_public_transports',
    active: false,
  },
  {
    id: 6,
    label: 'Nightlife',
    sublabel: 'Drive to bars/restaurants within 15min',
    key: 'inner_city_vibe',
    active: false,
  },
];

export const NORMAL_FILTERS = [
  'suburbs',
  'include_nearby_suburbs',
  'property_type',
  'price',
  'bedroom',
  'bathroom',
  'carparking',
  'land_size',
  'features',
  'keyword',
  'exclude_under_offers',
  // 'is_established',
];

export const RENT_FILTERS = [
  'suburbs',
  'include_nearby_suburbs',
  'property_type',
  'price_rent',
  'bedroom',
  'bathroom',
  'carparking',
  'land_size',
  'features',
  'available_date',
  'keyword',
  'exclude_under_offers',
  // 'is_established',
  'furnished',
];

export const MPP_FILTERS = [
  'state',
  'radius_distance',
  'property_type',
  'price',
  'bedroom',
  'bathroom',
  'carparking',
  'nearby_filters',
];

export const STATES_LABEL = [
  {
    label: 'New South Wales',
    id: 'NSW',
    value: 'NSW',
  },
  {
    label: 'Queensland',
    id: 'QLD',
    value: 'QLD',
  },
  {
    label: 'South Australia',
    id: 'SA',
    value: 'SA',
  },
  {
    label: 'Tasmania',
    id: 'TAS',
    value: 'TAS',
  },
  {
    label: 'Victoria',
    id: 'VIC',
    value: 'VIC',
  },
  {
    label: 'Western Australia',
    id: 'WA',
    value: 'WA',
  },
  {
    label: 'Australian Capital Territory',
    id: 'ACT',
    value: 'ACT',
  },
  {
    label: 'Northern Territory',
    id: 'NT',
    value: 'NT',
  },
];

export const DEFAULT_DOOR_URLS = [
  'https://img.propertymate.com.au/0tmup835/NFzY8JFdWq/J8MomwoQPg/10330688279206474527_7740323941261360596',
  'https://img.propertymate.com.au/0tmup835/NFzY8JFdWq/J8MomwoQPg/1073959950262617278_6067374095496339695',
  'https://img.propertymate.com.au/0tmup835/NFzY8JFdWq/J8MomwoQPg/1490343536631055484_7923463458828554445',
  'https://img.propertymate.com.au/0tmup835/NFzY8JFdWq/J8MomwoQPg/8011719814331434828_8535965008773332951',
  'https://img.propertymate.com.au/0tmup835/NFzY8JFdWq/J8MomwoQPg/16342726083372585671_1316091254702425553',
  'https://img.propertymate.com.au/0tmup835/NFzY8JFdWq/J8MomwoQPg/9907190409172144996_62932548959030460',
  'https://img.propertymate.com.au/0tmup835/NFzY8JFdWq/J8MomwoQPg/7295110842506887452_1259949841816800922',
  'https://img.propertymate.com.au/0tmup835/NFzY8JFdWq/J8MomwoQPg/11983054949809749208_6124508685544418683',
  'https://img.propertymate.com.au/0tmup835/NFzY8JFdWq/J8MomwoQPg/1544847326262699329_8529116779626242804',
];
