import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import DynamicIcons from '../DynamicIcons';

import {
  Typography,
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

const Container = styled.div`
  padding: 10px 0 20px;
`;

const StyledFormControl = withStyles({
  root: {
    width: '100%',
  },
})(FormControl);

const StyledInputLabel = withStyles({
  root: {},
})(InputLabel);

const StyledSelect = withStyles({
  root: {},
})(Select);

const StyledMenuItem = withStyles({
  root: {},
})(MenuItem);

const CustomSelect = (props) => {
  const [value, setValue] = React.useState(props.value || '');
  const [min, setMin] = React.useState(props.min || '');
  const [max, setMax] = React.useState(props.max || '');

  useEffect(() => {
    if (props.isClearFilter) {
      setValue('');
    }
  }, [props.isClearFilter]);

  const handleChange = (event) => {
    setValue(event.target.value);
    props.handleSelect(event.target.value);
  };

  useEffect(() => {
    setMin(props.min);
    setMax(props.max);
  }, [props.min, props.max]);

  return (
    <Container>
      <StyledFormControl variant='outlined'>
        <StyledSelect
          value={value}
          onChange={handleChange}
          // IconComponent={<DynamicIcons icon="ExpandMore" />}
        >
          {props.options &&
            props.options.map((item, index) => {
              if (min) {
                if (parseInt(item.value) >= min) {
                  return (
                    <StyledMenuItem value={item.value} key={index}>
                      {item.label}
                    </StyledMenuItem>
                  );
                } else if (item.value === 'any') {
                  return (
                    <StyledMenuItem value={item.value} key={index}>
                      {item.label}
                    </StyledMenuItem>
                  );
                }
              } else if (max) {
                if (parseInt(item.value) <= max) {
                  return (
                    <StyledMenuItem value={item.value} key={index}>
                      {item.label}
                    </StyledMenuItem>
                  );
                } else if (item.value === 'any') {
                  return (
                    <StyledMenuItem value={item.value} key={index}>
                      {item.label}
                    </StyledMenuItem>
                  );
                }
              } else {
                return (
                  <StyledMenuItem value={item.value} key={index}>
                    {item.label}
                  </StyledMenuItem>
                );
              }
            })}
        </StyledSelect>
      </StyledFormControl>
    </Container>
  );
};

export default CustomSelect;
