import { PURGE } from 'redux-persist';
import { AGENCY_ACTION_TYPES } from 'store/constants/agencyv2.constants';

const INITIAL_STATE = {
  // temporary values
  payload_request: null,
  payload_success: null,
  payload_failed: null,
  status: null,
  error: null,

  // stored values
  agencyuser: null,
  dashboardproperties: null,
  agency_agents: null,
  current_agent: null,
  current_agency: null,
  avatar_upload: null,
  agency_upload_image: null,
  agency_register: null,
  agency_contact_admin: null,
  agency_create_property: {
    payload_request: null,
    payload_success: null,
    payload_failed: null,
    error: null,
  },
  soi: null,
  agency_pause_property: {
    payload_request: null,
    payload_success: null,
    payload_failed: null,
    error: null,
  },
  agency_delete_property: {
    payload_request: null,
    payload_success: null,
    payload_failed: null,
    error: null,
  },
  agency_admins: {
    status: null,
    error: null,
    payload_request: null,
    payload_success: null,
    payload_failed: null,
    admins_list: null,
    current_admin: null,
  },
};

const agencyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AGENCY_ACTION_TYPES['AGENCY_LOGIN']['REQUEST']:
    case AGENCY_ACTION_TYPES['AGENCY_DASHBOARD_GET_PROPERTIES']['REQUEST']:
    case AGENCY_ACTION_TYPES['AGENCY_FORGOT_PASSWORD']['REQUEST']:
    case AGENCY_ACTION_TYPES['AGENCY_RESET_PASSWORD']['REQUEST']:
    case AGENCY_ACTION_TYPES['AGENCY_EDIT_PROPERTY']['REQUEST']:
    case AGENCY_ACTION_TYPES['AGENCY_PUBLISH_PROPERTY']['REQUEST']:
    case AGENCY_ACTION_TYPES['AGENCY_NOTIFY_VENDOR']['REQUEST']:
    case AGENCY_ACTION_TYPES['AGENCY_GET_ALL_AGENTS']['REQUEST']:
    case AGENCY_ACTION_TYPES['AGENCY_GET_AGENT_DETAILS']['REQUEST']:
    case AGENCY_ACTION_TYPES['AGENCY_PUT_AGENT_DETAILS']['REQUEST']:
    case AGENCY_ACTION_TYPES['AGENCY_CHECK_AGENT_EMAIL']['REQUEST']:
    case AGENCY_ACTION_TYPES['AGENCY_ADD_AGENT']['REQUEST']:
    case AGENCY_ACTION_TYPES['AGENCY_DELETE_AGENT']['REQUEST']:
    case AGENCY_ACTION_TYPES['GET_AGENCY_DETAILS']['REQUEST']:
    case AGENCY_ACTION_TYPES['UPDATE_AGENCY_DETAILS']['REQUEST']:
    case AGENCY_ACTION_TYPES['UPDATE_AGENCY_DETAILS_V2']['REQUEST']:
    case AGENCY_ACTION_TYPES['AGENCY_ACTIVATE']['REQUEST']:
    case AGENCY_ACTION_TYPES['AGENCY_POST_CHANGE_REQUEST']['REQUEST']:
    case AGENCY_ACTION_TYPES['AGENCY_SEND_AGENT_ACTIVATION_EMAIL']['REQUEST']:
      return {
        ...state,
        status: action.type,
        payload_request: action.params,
        error: null,
      };

    case AGENCY_ACTION_TYPES['AGENCY_AGENT_PHOTO']['REQUEST']:
      return {
        ...state,
        avatar_upload: {
          status: action.type,
          payload_request: action.params,
          error: null,
        },
      };
    case AGENCY_ACTION_TYPES['AGENCY_UPLOAD_IMAGE']['REQUEST']:
      return {
        ...state,
        agency_upload_image: {
          status: action.type,
          payload_request: action.params,
          error: null,
        },
      };
    case AGENCY_ACTION_TYPES['AGENCY_REGISTER']['REQUEST']:
      return {
        ...state,
        agency_register: {
          status: action.type,
          payload_request: action.params,
          error: null,
        },
      };
    case AGENCY_ACTION_TYPES['AGENCY_REGISTER_CONTACT_ADMIN']['REQUEST']:
      return {
        ...state,
        agency_contact_admin: {
          status: action.type,
          payload_request: action.params,
          error: null,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_CREATE_PROPERTY']['REQUEST']:
      return {
        ...state,
        agency_create_property: {
          status: action.type,
          payload_request: action.params,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_PAUSE_PROPERTY']['REQUEST']:
      return {
        ...state,
        agency_pause_property: {
          status: action.type,
          payload_request: action.params,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_DELETE_PROPERTY']['REQUEST']:
      return {
        ...state,
        agency_delete_property: {
          status: action.type,
          payload_request: action.params,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_CREATE_PROPERTY_SOI']['REQUEST']:
      return {
        ...state,
        soi: {
          status: action.type,
          payload_request: action.params,
          error: null,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_LOGIN']['FAILED']:
    case AGENCY_ACTION_TYPES['AGENCY_DASHBOARD_GET_PROPERTIES']['FAILED']:
    case AGENCY_ACTION_TYPES['AGENCY_FORGOT_PASSWORD']['FAILED']:
    case AGENCY_ACTION_TYPES['AGENCY_RESET_PASSWORD']['FAILED']:
    case AGENCY_ACTION_TYPES['AGENCY_EDIT_PROPERTY']['FAILED']:
    case AGENCY_ACTION_TYPES['AGENCY_PUBLISH_PROPERTY']['FAILED']:
    case AGENCY_ACTION_TYPES['AGENCY_NOTIFY_VENDOR']['FAILED']:
    case AGENCY_ACTION_TYPES['AGENCY_GET_ALL_AGENTS']['FAILED']:
    case AGENCY_ACTION_TYPES['AGENCY_GET_AGENT_DETAILS']['FAILED']:
    case AGENCY_ACTION_TYPES['AGENCY_PUT_AGENT_DETAILS']['FAILED']:
    case AGENCY_ACTION_TYPES['AGENCY_CHECK_AGENT_EMAIL']['FAILED']:
    case AGENCY_ACTION_TYPES['AGENCY_ADD_AGENT']['FAILED']:
    case AGENCY_ACTION_TYPES['AGENCY_DELETE_AGENT']['FAILED']:
    case AGENCY_ACTION_TYPES['GET_AGENCY_DETAILS']['FAILED']:
    case AGENCY_ACTION_TYPES['UPDATE_AGENCY_DETAILS']['FAILED']:
    case AGENCY_ACTION_TYPES['UPDATE_AGENCY_DETAILS_V2']['FAILED']:
    case AGENCY_ACTION_TYPES['AGENCY_ACTIVATE']['FAILED']:
    case AGENCY_ACTION_TYPES['AGENCY_POST_CHANGE_REQUEST']['FAILED']:
    case AGENCY_ACTION_TYPES['AGENCY_SEND_AGENT_ACTIVATION_EMAIL']['FAILED']:
      return {
        ...state,
        status: action.type,
        payload_failed: action.params,
        error: action.params.data,
      };

    case AGENCY_ACTION_TYPES['AGENCY_AGENT_PHOTO']['FAILED']:
      return {
        ...state,
        avatar_upload: {
          status: action.type,
          payload_failed: action.params,
          error: action.params.data,
        },
      };
    case AGENCY_ACTION_TYPES['AGENCY_UPLOAD_IMAGE']['FAILED']:
      return {
        ...state,
        agency_upload_image: {
          status: action.type,
          payload_failed: action.params,
          error: action.params.data,
        },
      };
    case AGENCY_ACTION_TYPES['AGENCY_REGISTER']['FAILED']:
      return {
        ...state,
        agency_register: {
          status: action.type,
          payload_failed: action.params,
          error: action.params.data,
        },
      };
    case AGENCY_ACTION_TYPES['AGENCY_REGISTER_CONTACT_ADMIN']['FAILED']:
      return {
        ...state,
        agency_contact_admin: {
          status: action.type,
          payload_failed: action.params,
          error: action.params.data,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_CREATE_PROPERTY']['FAILED']:
      return {
        ...state,
        agency_create_property: {
          status: action.type,
          payload_failed: action.params,
          error: action.params.data,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_PAUSE_PROPERTY']['FAILED']:
      return {
        ...state,
        agency_pause_property: {
          status: action.type,
          payload_failed: action.params,
          error: action.params.data,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_DELETE_PROPERTY']['FAILED']:
      return {
        ...state,
        agency_delete_property: {
          status: action.type,
          payload_failed: action.params,
          error: action.params.data,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_CREATE_PROPERTY_SOI']['FAILED']:
      return {
        ...state,
        soi: {
          status: action.type,
          payload_failed: action.params,
          error: action.params.data,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_LOGIN']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        agencyuser: action.params.data,
      };

    case AGENCY_ACTION_TYPES['AGENCY_DASHBOARD_GET_PROPERTIES']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        dashboardproperties: action.params.data,
      };
    case AGENCY_ACTION_TYPES['AGENCY_GET_ALL_AGENTS']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        agency_agents: action.params.data?.data,
      };
    case AGENCY_ACTION_TYPES['AGENCY_GET_AGENT_DETAILS']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        current_agent: action.params.data,
      };

    case AGENCY_ACTION_TYPES['GET_AGENCY_DETAILS']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        current_agency: action.params.data?.data?.entity,
      };
    case AGENCY_ACTION_TYPES['AGENCY_AGENT_PHOTO']['SUCCESS']:
      return {
        ...state,
        avatar_upload: {
          status: action.type,
          payload_success: action.params,
          response: action.params.data,
        },
      };
    case AGENCY_ACTION_TYPES['AGENCY_UPLOAD_IMAGE']['SUCCESS']:
      return {
        ...state,
        agency_upload_image: {
          status: action.type,
          payload_success: action.params,
          response: action.params.data,
        },
      };
    case AGENCY_ACTION_TYPES['AGENCY_REGISTER']['SUCCESS']:
      return {
        ...state,
        agency_register: {
          status: action.type,
          payload_success: action.params,
          response: action.params.data,
        },
      };
    case AGENCY_ACTION_TYPES['AGENCY_REGISTER_CONTACT_ADMIN']['SUCCESS']:
      return {
        ...state,
        agency_contact_admin: {
          status: action.type,
          payload_success: action.params,
          response: action.params.data,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_CREATE_PROPERTY']['SUCCESS']:
      return {
        ...state,
        agency_create_property: {
          status: action.type,
          payload_success: action.params,
          response: action.params.data,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_PAUSE_PROPERTY']['SUCCESS']:
      return {
        ...state,
        agency_pause_property: {
          status: action.type,
          payload_success: action.params,
          response: action.params.data,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_DELETE_PROPERTY']['SUCCESS']:
      return {
        ...state,
        agency_delete_property: {
          status: action.type,
          payload_success: action.params,
          response: action.params.data,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_CREATE_PROPERTY_SOI']['FAILED']:
      return {
        ...state,
        soi: {
          status: action.type,
          status: action.type,
          payload_success: action.params,
          response: action.params.data,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_FORGOT_PASSWORD']['SUCCESS']:
    case AGENCY_ACTION_TYPES['AGENCY_RESET_PASSWORD']['SUCCESS']:
    case AGENCY_ACTION_TYPES['AGENCY_EDIT_PROPERTY']['SUCCESS']:
    case AGENCY_ACTION_TYPES['AGENCY_PUBLISH_PROPERTY']['SUCCESS']:
    case AGENCY_ACTION_TYPES['AGENCY_NOTIFY_VENDOR']['SUCCESS']:
    case AGENCY_ACTION_TYPES['AGENCY_PUT_AGENT_DETAILS']['SUCCESS']:
    case AGENCY_ACTION_TYPES['AGENCY_CHECK_AGENT_EMAIL']['SUCCESS']:
    case AGENCY_ACTION_TYPES['AGENCY_ADD_AGENT']['SUCCESS']:
    case AGENCY_ACTION_TYPES['AGENCY_DELETE_AGENT']['SUCCESS']:
    case AGENCY_ACTION_TYPES['UPDATE_AGENCY_DETAILS']['SUCCESS']:
    case AGENCY_ACTION_TYPES['UPDATE_AGENCY_DETAILS_V2']['SUCCESS']:
    case AGENCY_ACTION_TYPES['AGENCY_ACTIVATE']['SUCCESS']:
    case AGENCY_ACTION_TYPES['AGENCY_POST_CHANGE_REQUEST']['SUCCESS']:
    case AGENCY_ACTION_TYPES['AGENCY_SEND_AGENT_ACTIVATION_EMAIL']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params.data,
      };

    case AGENCY_ACTION_TYPES['GENERAL_AGENCY']['CLEAR_ERROR']:
      return {
        ...state,
        status: null,
        payload_request: null,
        payload_success: null,
        payload_failed: null,
        error: null,
      };

    case AGENCY_ACTION_TYPES['GENERAL_AGENCY']['CLEAR_STATUS']:
      return {
        ...state,
        status: null,
      };

    case AGENCY_ACTION_TYPES['AGENCY_REGISTER_CONTACT_ADMIN']['CLEAR']:
      return {
        ...state,
        agency_contact_admin: null,
      };

    case AGENCY_ACTION_TYPES['AGENCY_REGISTER']['CLEAR']:
      return {
        ...state,
        agency_register: null,
      };

    case AGENCY_ACTION_TYPES['AGENCY_UPLOAD_IMAGE']['CLEAR']:
      return {
        ...state,
        agency_upload_image: null,
      };

    case AGENCY_ACTION_TYPES['AGENCY_DASHBOARD_GET_PROPERTIES']['CLEAR']:
      return {
        ...state,
        dashboardproperties: null,
      };

    case AGENCY_ACTION_TYPES['AGENCY_CREATE_PROPERTY']['CLEAR']:
      return {
        ...state,
        agency_create_property: {
          payload_request: null,
          payload_success: null,
          payload_failed: null,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_PAUSE_PROPERTY']['CLEAR']:
      return {
        ...state,
        agency_pause_property: {
          payload_request: null,
          payload_success: null,
          payload_failed: null,
          error: null,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_DELETE_PROPERTY']['CLEAR']:
      return {
        ...state,
        agency_delete_property: {
          payload_request: null,
          payload_success: null,
          payload_failed: null,
          error: null,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_CREATE_PROPERTY_SOI']['CLEAR']:
      return {
        ...state,
        soi: null,
      };
    case AGENCY_ACTION_TYPES['AGENCY_UPLOAD_IMAGE']['CLEAR_STATUS']:
      return {
        ...state,
        agency_upload_image: {
          ...state.agency_upload_image,
          status: null,
        },
      };
    case AGENCY_ACTION_TYPES['AGENCY_UPLOAD_IMAGE']['CLEAR_ERROR']:
      return {
        ...state,
        agency_upload_image: {
          ...state.agency_upload_image,
          error: null,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_GET_ALL_ADMINS']['REQUEST']:
      return {
        ...state,
        agency_admins: {
          ...state.agency_admins,
          status: action.type,
          error: null,
          payload_request: action.params,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_GET_ALL_ADMINS']['FAILED']:
      return {
        ...state,
        agency_admins: {
          ...state.agency_admins,
          status: action.type,
          error: action.params.data,
          payload_failed: action.params,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_GET_ALL_ADMINS']['SUCCESS']:
      return {
        ...state,
        agency_admins: {
          ...state.agency_admins,
          status: action.type,
          error: null,
          payload_failed: null,
          admins_list: action.params.data?.data,
        },
      };
    case AGENCY_ACTION_TYPES['AGENCY_GET_SINGLE_ADMIN']['REQUEST']:
      return {
        ...state,
        agency_admins: {
          ...state.agency_admins,
          status: action.type,
          error: null,
          payload_request: action.params,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_GET_SINGLE_ADMIN']['FAILED']:
      return {
        ...state,
        agency_admins: {
          ...state.agency_admins,
          status: action.type,
          error: action.params.data,
          payload_failed: action.params,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_GET_SINGLE_ADMIN']['SUCCESS']:
      return {
        ...state,
        agency_admins: {
          ...state.agency_admins,
          status: action.type,
          error: null,
          payload_failed: null,
          current_admin: action.params.data?.data,
        },
      };
    case AGENCY_ACTION_TYPES['AGENCY_EDIT_ADMIN']['REQUEST']:
      return {
        ...state,
        agency_admins: {
          ...state.agency_admins,
          status: action.type,
          error: null,
          payload_request: action.params,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_EDIT_ADMIN']['FAILED']:
      return {
        ...state,
        agency_admins: {
          ...state.agency_admins,
          status: action.type,
          error: action.params.data,
          payload_failed: action.params,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_EDIT_ADMIN']['SUCCESS']:
      return {
        ...state,
        agency_admins: {
          ...state.agency_admins,
          status: action.type,
          error: null,
          payload_failed: null,
          payload_success: action.params.data?.data,
        },
      };
    case AGENCY_ACTION_TYPES['AGENCY_CREATE_ADMIN']['REQUEST']:
      return {
        ...state,
        agency_admins: {
          ...state.agency_admins,
          status: action.type,
          error: null,
          payload_request: action.params,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_CREATE_ADMIN']['FAILED']:
      return {
        ...state,
        agency_admins: {
          ...state.agency_admins,
          status: action.type,
          error: action.params.data,
          payload_failed: action.params,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_CREATE_ADMIN']['SUCCESS']:
      return {
        ...state,
        agency_admins: {
          ...state.agency_admins,
          status: action.type,
          error: null,
          payload_failed: null,
          payload_success: action.params.data,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_DELETE_ADMIN']['REQUEST']:
      return {
        ...state,
        agency_admins: {
          ...state.agency_admins,
          status: action.type,
          error: null,
          payload_request: action.params,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_DELETE_ADMIN']['FAILED']:
      return {
        ...state,
        agency_admins: {
          ...state.agency_admins,
          status: action.type,
          error: action.params.data,
          payload_failed: action.params,
        },
      };

    case AGENCY_ACTION_TYPES['AGENCY_DELETE_ADMIN']['SUCCESS']:
      return {
        ...state,
        agency_admins: {
          ...state.agency_admins,
          status: action.type,
          error: null,
          payload_failed: null,
          payload_success: action.params.data,
        },
      };

    case AGENCY_ACTION_TYPES['GENERAL_AGENCY']['RESET_STATES']:
      return INITIAL_STATE;
    case PURGE:
      return INITIAL_STATE;

    default: {
      return state;
    }
  }
};

export default agencyReducer;
