import FavoritesV2 from '../../containers/Web/FavoritesV2'
//import Favorites from '../../containers/Web/Favorites'
import Property from '../../containers/Web/Property'
import PropertyMapView from '../../containers/Web/PropertyMapView'
import PropertyStreetView from '../../containers/Web/PropertyStreetView'
import FavoritesMap from '../../containers/Web/FavoritesMap'

let routes = [
  {
    path: '/favourites',
    component: FavoritesV2,
  },
  {
    path: '/favourites/map-view',
    component: FavoritesMap,
  },
  {
    path: '/favourites/:id',
    component: Property,
  },
  {
    path: '/favourites/:id/location/map',
    component: PropertyMapView,
  },
  {
    path: '/favourites/:id/location/street',
    component: PropertyStreetView,
  },
]

export default routes
