import { defaultUrl, defaultUrl2 } from './config';

export const createUrl = (url, default_url = defaultUrl) => {
  return `${default_url}${url}`;
};

export const createUrl2 = (url, default_url = defaultUrl2) => {
  return `${default_url}${url}`;
};

export const capitalizeName = (string) => {
  const parts = string?.split('/');
  if (parts[0].includes('.')) {
    return parts[0] + ' / ' + parts[1];
  } else {
    return (
      parts[0].replace(/\b./g, function (m) {
        return m.toUpperCase();
      }) +
      ' / ' +
      parts[1]
    );
  }
};

export const formatNumString = (string) => {
  if (string === '') {
    return '0';
  } else if (isNaN(parseInt(string))) {
    return '0';
  } else {
    return parseInt(string).toLocaleString();
  }
};

export const filterArray = (array, filter, key) => {
  const arr = array.filter(filter);
  if (arr.length > 0) {
    return array.filter(filter)[0][key];
  } else {
    return '';
  }
};
